import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import TurndownService from 'turndown';

interface TextEditorProps {
  saveContent: (content: string) => void;
  initialContent?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  saveContent,
  initialContent = '',
}) => {
  const quillRef = useRef<ReactQuill>(null);
  const turndownService = new TurndownService();
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    const quillInstance = quillRef.current?.getEditor();
    if (quillInstance && !hasInitialized) {
      const html = turndownService.turndown(initialContent);
      quillInstance.setContents(quillInstance.clipboard.convert(html));
      setHasInitialized(true);
    }
  }, [initialContent]);

  const handleTextChange = () => {
    const quillInstance = quillRef.current?.getEditor();
    if (quillInstance) {
      const htmlContent = quillInstance.root.innerHTML;
      const markdown = turndownService.turndown(htmlContent);
      saveContent(markdown);
    }
  };

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        onChange={handleTextChange}
        modules={{ toolbar: false }}
        formats={[]}
        style={{ whiteSpace: 'pre-wrap' }}
      />
    </div>
  );
};

export { TextEditor };
