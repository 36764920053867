import React, { useState, SyntheticEvent } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

import AccountSettings from './AccountSettings';
import TabPanel from '../../components/common/TabPanel';
import { settingsTabLabels } from '../../constants';
import { Container } from '../../dependencyInjection/Container';

interface SettingsProps {
  container: Container;
}

const Settings: React.FC<SettingsProps> = ({ container }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabPanelItems = [<AccountSettings container={container} />];

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        {settingsTabLabels.map((label, index) => (
          <Tab key={`Tab-${index}`} value={index} label={label} />
        ))}
        ;
      </Tabs>

      {tabPanelItems.map((item, index) => (
        <TabPanel key={`TabPanel-${index}`} value={value} index={index}>
          {item}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Settings;
