import React from 'react';

import {
  apiAssignmentMetadataSections,
  platformAssignmentMetadataSections,
  PLATFORM_ROW_DETAIL_TYPE,
} from '../../../constants';
import { AssignmentMetadataContent } from '../../../types';

interface AssignmentMetaDataProps {
  metadata?: AssignmentMetadataContent | null;
  type?: string;
}

const AssignmentMetaData: React.FC<AssignmentMetaDataProps> = ({
  metadata,
  type = PLATFORM_ROW_DETAIL_TYPE,
}) => {
  if (!metadata) {
    return null;
  }

  const sections =
    type === PLATFORM_ROW_DETAIL_TYPE
      ? platformAssignmentMetadataSections
      : apiAssignmentMetadataSections;

  return (
    <div>
      {sections.map(({ items, key }) => (
        <div key={key} className="flex gap-4">
          {items.map(({ key, label }) => (
            <div key={key}>
              <span className="font-bold">{label}: </span>
              {metadata[key] || '-'}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AssignmentMetaData;
