export const totalTokens = [
  {
    key: 'completion_tokens',
    label: 'Completion Tokens: ',
  },
  {
    key: 'prompt_tokens',
    label: 'Prompt Tokens: ',
  },
  {
    key: 'total_tokens',
    label: 'Total Tokens: ',
  },
];
