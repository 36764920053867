import React from 'react';

import SettingCard from '../../common/ContentCard';
import BasicInfoForm from './BasicInfoForm';
import { Profile } from '../../../models/user';

interface BasicInfoCardProps {
  data: Profile;
  isLoading: boolean;
}

const BasicInfoCard: React.FC<BasicInfoCardProps> = ({ data, isLoading }) => {
  return (
    <SettingCard
      children={<BasicInfoForm defaultData={data} />}
      title="Basic Information"
    />
  );
};

export default BasicInfoCard;
