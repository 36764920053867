import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import TextInput from '../common/TextInput';
import CustomSelect from '../common/CustomSelect';
import ChipsInput from '../common/ChipsInput';
import { useNotification } from '../../contexts/NotificationProvider';
import { useApi } from '../../contexts/ApiProvider';
import { objectIncludes } from '../../utils';
import { accountDetailsSchema, AccountDetailsForm } from '../../validation';
import {
  departmentOptions,
  typeOfSchoolOptions,
  stateAbbreviations,
} from '../../constants';
import { Profile } from '../../models/user';

interface AccountDetailsFormProps {
  isEditing?: boolean;
  defaultData?: Profile;
}

const defaultValue: Profile = {
  schoolName: '',
  department: '',
  typeOfSchool: '',
  classes: [],
  city: '',
  state: '',
  zip: '',
};

const AccountDetails: React.FC<AccountDetailsFormProps> = ({
  isEditing = false,
  defaultData = defaultValue,
}) => {
  const { showNotification } = useNotification();
  const { api } = useApi();
  const navigate = useNavigate();
  const { schoolName, department, typeOfSchool, classes, city, state, zip } =
    defaultData;

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    trigger,
    setValue,
  } = useForm<AccountDetailsForm>({
    resolver: zodResolver(accountDetailsSchema),
  });

  const onSubmit = async (data: AccountDetailsForm) => {
    if (objectIncludes(defaultData, data)) {
      return;
    }
    try {
      setIsLoading(true);

      const userId = api.getCurrentUserId();
      await api.updateUserDocument(userId, data);

      if (!isEditing) {
        navigate('/assignments');
      }
    } catch (error) {
      console.error('Error creating account: ', error);
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred.';
      showNotification(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 w-full">
          <TextInput
            id="schoolName"
            label="School Name"
            defaultValue={isEditing ? schoolName : ''}
            fieldRegister={register('schoolName')}
            fieldError={errors.schoolName}
            isSubmitted={isSubmitted}
          />
          <CustomSelect
            id="department"
            label="Department"
            defaultValue={isEditing ? department : ''}
            fieldRegister={register('department')}
            fieldError={errors.department}
            options={departmentOptions}
            validateField={trigger}
            setFieldValue={setValue}
            isSubmitted={isSubmitted}
            isEditing={isEditing}
            includesCustom
          />
          <CustomSelect
            id="typeOfSchool"
            label="Type of School"
            defaultValue={isEditing ? typeOfSchool : ''}
            fieldRegister={register('typeOfSchool')}
            fieldError={errors.typeOfSchool}
            options={typeOfSchoolOptions}
            validateField={trigger}
            setFieldValue={setValue}
            isSubmitted={isSubmitted}
          />
        </div>
        <div className="flex gap-4 w-full">
          <ChipsInput
            id="classes"
            label="Name of classes"
            defaultValue={classes}
            validateField={trigger}
            setFieldValue={setValue}
            errors={errors}
            isSubmitted={isSubmitted}
          />
        </div>
        <div
          className="grid gap-4"
          style={{ gridTemplateColumns: '5fr 2fr 3fr' }}
        >
          <TextInput
            id="city"
            label="City"
            defaultValue={isEditing ? city : ''}
            fieldRegister={register('city')}
            fieldError={errors.city}
            isSubmitted={isSubmitted}
          />
          <CustomSelect
            id="state"
            label="State"
            defaultValue={state}
            fieldRegister={register('state')}
            fieldError={errors.state}
            options={stateAbbreviations}
            validateField={trigger}
            setFieldValue={setValue}
            isSubmitted={isSubmitted}
          />
          <TextInput
            id="zip"
            label="ZIP code"
            defaultValue={isEditing ? zip : ''}
            fieldRegister={register('zip')}
            fieldError={errors.zip}
            isSubmitted={isSubmitted}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className={`justify-center owler-indigo-button w-32 ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isLoading}
          aria-disabled={isLoading}
        >
          {isLoading ? 'Loading...' : isEditing ? 'Save changes' : 'NEXT'}
        </button>
      </div>
    </form>
  );
};

export default AccountDetails;
