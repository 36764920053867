import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

interface NotificationContextType {
  showNotification: (message: string, severity: AlertColor) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<AlertColor>('info');

  useEffect(() => {
    const storedMessage = localStorage.getItem('notificationMessage');
    const storedSeverity = localStorage.getItem('notificationSeverity');
    if (storedMessage && storedSeverity) {
      setMessage(storedMessage);
      setSeverity(storedSeverity as AlertColor);
      setOpen(true);
    }
  }, []);

  const showNotification = (msg: string, sev: AlertColor) => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
    localStorage.setItem('notificationMessage', msg);
    localStorage.setItem('notificationSeverity', sev);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
    setSeverity('info');
    localStorage.removeItem('notificationMessage');
    localStorage.removeItem('notificationSeverity');
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      {open && message && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </NotificationContext.Provider>
  );
};
