import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

interface TextInputProps {
  id: string;
  label: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  variant?: TextFieldProps['variant'];
  defaultValue?: string | number;
  fieldRegister: UseFormRegisterReturn;
  fieldError?: FieldError;
  isSubmitted: boolean;
  isNumber?: boolean;
  autoFocus?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  defaultValue = '',
  placeholder = '',
  multiline = false,
  rows = 1,
  variant = 'outlined',
  fieldRegister,
  fieldError,
  isSubmitted,
  isNumber = false,
  autoFocus = false,
}) => {
  const errorMessage = fieldError && isSubmitted ? fieldError.message : '';

  return (
    <TextField
      id={id}
      label={label}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      variant={variant}
      type={isNumber ? 'number' : 'text'}
      defaultValue={defaultValue}
      {...fieldRegister}
      error={!!errorMessage}
      helperText={errorMessage}
      fullWidth
      margin="normal"
      autoFocus={autoFocus}
      inputProps={{
        className: 'focus:ring-0',
        min: isNumber ? 0 : undefined,
      }}
      aria-invalid={!!errorMessage}
    />
  );
};

export default TextInput;
