import React from 'react';

import SignupForm from '../../components/auth/SignupForm';

const SignupView: React.FC = () => {
  return (
    <div className="flex min-h-full flex-1 flex-col gap-8 justify-center px-6 py-16 lg:px-8 sm:mx-auto sm:w-full sm:max-w-2xl">
      <div className="flex flex-col gap-4 w-full">
        <h2 className="text-4xl font-bold leading-9 tracking-tight text-gray-900">
          Create account
        </h2>
        <p className="text-sm text-gray-500">
          Faster feedback is just a few clicks away.
        </p>
      </div>

      <div className="w-full">
        <SignupForm />
      </div>
    </div>
  );
};

export default SignupView;
