import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AssignmentReference } from '../components/assignments/AssignmentList/AssignmentReference';
import { useApi } from './ApiProvider';
import { Container } from '../dependencyInjection/Container';

interface AssignmentsContextType {
  assignments: AssignmentReference[] | undefined;
  isLoading: boolean;
  updateAssignmentName: (
    assignmentId: string,
    newName: string
  ) => Promise<void>;
  updateAssignmentArchived: (assignmentId: string) => Promise<void>;
  refreshAssignments: () => Promise<void>;
}

const AssignmentsContext = createContext<AssignmentsContextType | undefined>(
  undefined
);

interface AssignmentsContextProviderProps {
  container: Container;
  children: React.ReactNode;
}

export const AssignmentsContextProvider: React.FC<
  AssignmentsContextProviderProps
> = ({ container, children }) => {
  const { api } = useApi();
  const userId = api.getCurrentUserId();
  const assignmentsProvider = container.assignmentsProvider;
  const queryClient = useQueryClient();

  const fetchAssignments = useCallback(
    () => assignmentsProvider.get(),
    [assignmentsProvider]
  );

  const {
    data: assignments,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['assignments'],
    queryFn: fetchAssignments,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const unarchivedAssignments = useMemo(() => {
    if (!assignments) return [];

    return assignments.filter((assignment) => !assignment.isArchived);
  }, [assignments]);

  const updateAssignmentName = useCallback(
    async (assignmentId: string, newName: string) => {
      try {
        // Update in Firebase
        await api.updateAssignment(userId, assignmentId, {
          assignment_name: newName,
        });

        // Update local cache
        queryClient.setQueryData(
          ['assignments'],
          (oldData: AssignmentReference[] | undefined) =>
            oldData?.map((assignment) =>
              assignment.assignment_id === assignmentId
                ? { ...assignment, assignment_name: newName }
                : assignment
            )
        );

        // Refetch to ensure consistency with the server
        await refetch();
      } catch (error) {
        console.error('Error updating assignment name:', error);
        throw error;
      }
    },
    [api, userId, queryClient, assignments, refetch]
  );

  const updateAssignmentArchived = useCallback(
    async (assignmentId: string) => {
      try {
        // Update in Firebase
        await api.updateAssignment(userId, assignmentId, { isArchived: true });

        // Update local cache
        queryClient.setQueryData(
          ['assignments'],
          (oldData: AssignmentReference[] | undefined) =>
            oldData?.map((assignment) =>
              assignment.assignment_id === assignmentId
                ? { ...assignment, isArchived: true }
                : assignment
            )
        );

        // Refetch to ensure consistency with the server
        await refetch();
      } catch (error) {
        console.error('Error updating assignment name:', error);
        throw error;
      }
    },
    [api, userId, queryClient, assignments, refetch]
  );

  const refreshAssignments = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <AssignmentsContext.Provider
      value={{
        assignments: unarchivedAssignments,
        isLoading,
        updateAssignmentName,
        updateAssignmentArchived,
        refreshAssignments,
      }}
    >
      {children}
    </AssignmentsContext.Provider>
  );
};

export const useAssignmentsContext = () => {
  const context = useContext(AssignmentsContext);
  if (context === undefined) {
    throw new Error(
      'useAssignmentsContext must be used within an AssignmentsContextProvider'
    );
  }
  return context;
};
