import React from 'react';
import ListElementHeader from '../utils/ListElementHeader';
import { GoogleClassroomCourseWork } from '../../types';
import AssignmentSelectionButtons from './AssignmentList/AssignmentSelectionButtons';

type GCAssignmentViewProps = {
  courseTitle: string;
  courseId: string;
  assignment: GoogleClassroomCourseWork;
  onClick: (id: string) => void;
  isSelected: boolean;
  onSelect: (
    courseTitle: string,
    assignment: GoogleClassroomCourseWork,
    courseId: string
  ) => void;
};

export const GCAssignmentView = ({
  courseTitle,
  courseId,
  assignment,
  onClick,
  isSelected,
  onSelect,
}: GCAssignmentViewProps) => {
  return (
    <div
      onClick={() => onClick(assignment.id)}
      className="cursor-pointer min-w-0"
    >
      <ListElementHeader text={assignment.title} />
      {isSelected && (
        <div className="mt-4 text-gray-800">
          <p className="mt-1 text-sm">
            <span className="font-bold">Course:</span> {courseTitle}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Question:</span> {assignment.title}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Question Background:</span>{' '}
            {assignment.description}
          </p>
          <AssignmentSelectionButtons
            reuseCallback={() => onSelect(courseTitle, assignment, courseId)}
            reuseDescription="Use this"
          />
        </div>
      )}
    </div>
  );
};
