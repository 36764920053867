import React from 'react';
import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license';
import './index.css';
import App from './App';
import firebaseApp from './firebaseAppFactory';
import { createContainer } from './dependencyInjection/createContainer';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

import * as Sentry from '@sentry/react';

// MODE is set by Vite depending on how it was launched
if (import.meta.env.MODE === undefined) {
  throw new Error('MODE is not defined');
}

Sentry.init({
  dsn: 'https://8991afe01119d087ba0278fafc29f22c@o4506100158627840.ingest.sentry.io/4506100159807488',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App createContainer={() => createContainer(firebaseApp)} />
  </React.StrictMode>
);
