import React, { useMemo } from 'react';

import EditIconButton from '../common/EditIconButton';
import { Profile } from '../../models/user';

interface GeneralInfoCardProps {
  data: Profile;
}

const GeneralInfoCard: React.FC<GeneralInfoCardProps> = ({
  data: {
    email,
    schoolName,
    typeOfSchool,
    department,
    city,
    state,
    zip,
    classes,
  },
}) => {
  const fullAddress = useMemo(
    () => (city && state && zip ? `${city}, ${state} ${zip}` : ''),
    [city, state, zip]
  );
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="flex justify-between items-center px-4 py-5 sm:px-6">
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          General Information
        </h3>
        <EditIconButton />
      </div>
      <div>
        <dl>
          <InfoItem term="Email" description={email || ''} />
          <InfoItem term="School" description={schoolName || ''} />
          <InfoItem term="Type of School" description={typeOfSchool || ''} />
          <InfoItem term="Department" description={department || ''} />
          <InfoItem term="Location" description={fullAddress} />
          <InfoItem
            term="Name of classes"
            description={(classes || []).join(', ')}
          />
        </dl>
      </div>
    </div>
  );
};

interface InfoItemProps {
  term: string;
  description: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ term, description }) => {
  return (
    <div className="py-4 px-5 sm:py-5 sm:px-6 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {description}
      </dd>
    </div>
  );
};

export default GeneralInfoCard;
