import React, { useState, useEffect } from 'react';

import ScoreEditor from './ScoreEditor';
import ScoreSlider from './ScoreSlider';
import { formatNumber } from '../../utils';

export interface PointsRangeProps {
  pointsEarned: number;
  pointsPossible: number;
  hideScore?: boolean;
  saveContent?: (content: string | boolean) => void;
  minimumPoints?: number;
  numberOfSteps?: number;
}

const PointsRange: React.FC<PointsRangeProps> = ({
  pointsEarned,
  pointsPossible,
  hideScore = false,
  saveContent,
  minimumPoints = 0,
  numberOfSteps = 20,
}) => {
  const [value, setValue] = useState(`${pointsEarned}`);
  const [currentPoints, setCurrentPoints] = useState(pointsEarned);
  const [originalValue, setOriginalValue] = useState(`${pointsEarned}`);
  const [originalPoints, setOriginalPoints] = useState(pointsEarned);

  useEffect(() => {
    const inputNumber = Number(value);
    setCurrentPoints(
      formatNumber(Math.max(Math.min(inputNumber, pointsPossible), 0))
    );
  }, [value]);

  const handleRevert = () => {
    saveContent?.(false);
  };

  const handleEdit = () => {
    setOriginalValue(value);
    setOriginalPoints(currentPoints);
  };

  const handleRemove = () => {
    saveContent?.(true);
  };

  const handleSave = () => {
    saveContent?.(`${currentPoints}`);
  };

  const handleCancel = () => {
    setValue(originalValue);
    setOriginalPoints(originalPoints);
  };

  const handleSetValue = (newValue: string) => {
    setValue(newValue);
  };

  const handleSetCurrentPoints = (newValue: number) => {
    setCurrentPoints(newValue);
  };

  return (
    <div>
      <ScoreEditor
        value={value}
        handleSetValue={handleSetValue}
        currentPoints={currentPoints}
        revertCallback={handleRevert}
        editCallback={handleEdit}
        removeCallback={handleRemove}
        saveCallback={handleSave}
        cancelCallback={handleCancel}
        hideScore={hideScore}
      />
      <ScoreSlider
        currentPoints={currentPoints}
        pointsPossible={pointsPossible}
        saveContent={saveContent}
        handleSetValue={(newValue) => {
          setTimeout(() => handleSetValue(newValue), 0);
        }}
        handleSetCurrentPoints={(newValue) => {
          setTimeout(() => handleSetCurrentPoints(newValue), 0);
        }}
        numberOfSteps={numberOfSteps}
        minimumPoints={minimumPoints}
      />
    </div>
  );
};

export default PointsRange;
