import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox, FormHelperText } from '@mui/material';
import { FirebaseError } from 'firebase/app';

import TextInput from '../common/TextInput';
import PasswordInput from '../common/PasswordInput';
import { useNotification } from '../../contexts/NotificationProvider';
import { useApi } from '../../contexts/ApiProvider';
import { getFirebaseAuthErrorMessage } from '../../utils';
import { signupSchema, SignupForm } from '../../validation';
// import GoogleLogo from '../../assets/svgs/google.svg';

const Signup: React.FC = ({}) => {
  const { showNotification } = useNotification();
  const { api, analyticsApi } = useApi();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<SignupForm>({
    resolver: zodResolver(signupSchema),
  });

  const onSubmit = async (data: SignupForm) => {
    const { email, password, firstName, lastName } = data;
    try {
      setIsLoading(true);

      const { userId } = await api.signup(email, password);
      analyticsApi.logSignupPageCompletion(userId);

      const newData = {
        firstName,
        lastName,
      };

      await api.updateUserDocument(userId, newData);
    } catch (error) {
      console.error('Error creating account: ', error);
      const errorMessage = getFirebaseAuthErrorMessage(error as FirebaseError);
      showNotification(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // const signUpWithGoogle = async () => {
  //   try {
  //     await api.signUpWithGoogle();
  //   } catch (error) {
  //     console.error('Error creating account with Google: ', error);
  //     const errorMessage = getFirebaseAuthErrorMessage(error as FirebaseError);
  //     showNotification(errorMessage, 'error');
  //   }
  // };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 w-full">
          <TextInput
            id="firstName"
            label="First Name"
            fieldRegister={register('firstName')}
            fieldError={errors.firstName}
            isSubmitted={isSubmitted}
          />
          <TextInput
            id="lastName"
            label="Last Name"
            fieldRegister={register('lastName')}
            fieldError={errors.lastName}
            isSubmitted={isSubmitted}
          />
        </div>

        <div className="flex gap-4 w-full">
          <TextInput
            id="email"
            label="Email"
            fieldRegister={register('email')}
            fieldError={errors.email}
            isSubmitted={isSubmitted}
          />
          <PasswordInput
            id="password"
            label="Password"
            fieldRegister={register('password')}
            fieldError={errors.password}
          />
        </div>
      </div>

      {/* <div className="flex justify-center w-full">
        <button
          type="button"
          className="flex items-center justify-center w-2/3 py-3 px-4 rounded-md shadow-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={signUpWithGoogle}
        >
          <img src={GoogleLogo} alt="Google" className="w-6 h-6 mr-2" />
          Register with Google
        </button>
      </div> */}
      <div>
        <div className="flex items-center">
          <Checkbox {...register('termsAccepted')} />
          <div className="text-xs">
            I agree to all the{' '}
            <Link
              to="/terms"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Terms
            </Link>{' '}
            and{' '}
            <Link
              to="/privacy"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Privacy policy
            </Link>
          </div>
        </div>
        {errors.termsAccepted && (
          <FormHelperText error>{errors.termsAccepted.message}</FormHelperText>
        )}
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className={`w-full justify-center owler-indigo-button ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isLoading}
          aria-disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Create account'}
        </button>
      </div>
    </form>
  );
};

export default Signup;
