import React, {
  useState,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
} from 'react';
import { TextField, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ChipData {
  key: number;
  label: string;
}

interface ChipsInputProps {
  id: string;
  label: string;
  defaultValue?: string[];
  setFieldValue: any;
  validateField: any;
  errors: any;
  isSubmitted: boolean;
}

const TextFieldStyled = styled(TextField)((props) => {
  return `
    max-width: 100%;

    .MuiInputBase-root {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      row-gap: 5px;
      padding-top: ${props.size === 'small' ? '5px' : '9px'};
      padding-right: ${props.InputProps?.endAdornment ? '30px' : '9px'};
      padding-bottom: ${props.size === 'small' ? '5px' : '9px'};
      padding-left: 10px;

      input {
        min-width: 30px;
        width: auto;
        flex-grow: 1;
        text-overflow: ellipsis;
        padding: ${props.size === 'small' ? '3.5px 4px' : '7.5px 4px'};
        align-self: center;
      }
    }
  `;
});

const ChipsInput: React.FC<ChipsInputProps> = ({
  id,
  label,
  defaultValue = [],
  setFieldValue,
  validateField,
  errors,
  isSubmitted,
}) => {
  const defaultChipData = defaultValue.map((item, index) => ({
    key: index,
    label: item,
  }));
  const [chips, setChips] = useState<ChipData[]>(defaultChipData);
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const fieldError = errors.classes;

  useEffect(() => {
    const labels = [
      ...chips.map((chip) => chip.label),
      ...(value.trim() ? [value.trim()] : []),
    ];
    setFieldValue(id, labels);
    validateField();
  }, [chips, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const ENTER_KEY = 'Enter';

    if (event.key === ENTER_KEY && value.trim()) {
      event.preventDefault();
      setChips((chips) => [
        ...chips,
        {
          key: chips.length ? chips[chips.length - 1].key + 1 : 1,
          label: value.trim(),
        },
      ]);
      setValue('');

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChips((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  return (
    <TextFieldStyled
      id={id}
      label={label}
      variant="outlined"
      value={value}
      onChange={handleChange}
      error={!!fieldError && isSubmitted}
      helperText={!!fieldError && isSubmitted ? fieldError.message : ''}
      fullWidth
      margin="normal"
      inputRef={inputRef}
      inputProps={{
        onKeyDown: handleKeyDown,
        className: 'focus:ring-0',
      }}
      InputProps={{
        startAdornment:
          chips && chips.length
            ? chips.map((chip) => (
                <div key={chip.key} className="mr-1 my-1">
                  <Chip label={chip.label} onDelete={handleDelete(chip)} />
                </div>
              ))
            : null,
      }}
      aria-invalid={!!fieldError && isSubmitted ? 'true' : 'false'}
    />
  );
};

export default ChipsInput;
