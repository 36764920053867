import { useQuery } from '@tanstack/react-query';
import { SubmissionsProvider } from './useSubmissions';
import { AssignmentReference } from '../AssignmentList/AssignmentReference';

interface Scores {
  mean?: string;
  median?: string;
  mode?: string;
}

export interface SubmissionScore extends Scores {
  isLoading: boolean;
  error: Error | null;
}

const defaultValue: Scores = {
  mean: 'No Mean',
  median: 'No Median',
  mode: 'No Mode',
};

export const useSubmissionScores = (
  submissionsProvider: SubmissionsProvider,
  assignmentReference?: AssignmentReference
): SubmissionScore => {
  const formatScore = (number: number): number => parseFloat(number.toFixed(2));

  const calculateMean = (numbers: number[]): number => {
    const sum = numbers.reduce((acc, num) => acc + num, 0);
    return sum / numbers.length;
  };

  const calculateMedian = (numbers: number[]): number => {
    const sorted = [...numbers].sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[mid - 1] + sorted[mid]) / 2;
    } else {
      return sorted[mid];
    }
  };

  const calculateMode = (numbers: number[]): number[] | null => {
    if (numbers.length === 1) {
      return numbers;
    }
    const frequency: { [key: number]: number } = {};
    let maxFreq = 0;
    let modes: number[] = [];

    numbers.forEach((num) => {
      frequency[num] = (frequency[num] || 0) + 1;
      if (frequency[num] > maxFreq) {
        maxFreq = frequency[num];
      }
    });

    for (const num in frequency) {
      if (frequency[num] === maxFreq) {
        modes.push(Number(num));
      }
    }

    if (modes.length === numbers.length) {
      return null;
    }

    return modes;
  };

  const calculateScores = async (): Promise<Scores> => {
    try {
      if (!assignmentReference) return defaultValue;

      const scores =
        await submissionsProvider.getSubmissionScores(assignmentReference);

      if (!scores.length) {
        return defaultValue;
      }

      const modes = calculateMode(scores);
      const mean = `${formatScore(calculateMean(scores))}`;
      const median = `${formatScore(calculateMedian(scores))}`;
      const mode = `${modes ? modes.join(', ') : 'No mode'}`;

      return { mean, median, mode };
    } catch (error) {
      console.error('Error calculating scores:', error);
      return defaultValue;
    }
  };

  const { data, isLoading, error } = useQuery<Scores | null>({
    queryKey: ['calculateScores', assignmentReference?.assignment_id],
    queryFn: calculateScores,
  });

  return { ...data, isLoading, error };
};
