import React, { useState } from 'react';
import { Chip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Cached as CachedIcon,
} from '@mui/icons-material';

import TooltipIconButton from '../common/TooltipIconButton';

export interface ScoreEditorProps {
  value: string;
  handleSetValue: (value: string) => void;
  currentPoints?: number;
  revertCallback?: () => void;
  editCallback?: () => void;
  removeCallback?: () => void;
  saveCallback?: () => void;
  cancelCallback?: () => void;
  hideScore?: boolean;
  isTotal?: boolean;
}

const ScoreEditor: React.FC<ScoreEditorProps> = ({
  value,
  currentPoints,
  handleSetValue,
  revertCallback,
  editCallback,
  removeCallback,
  saveCallback,
  cancelCallback,
  hideScore = false,
  isTotal = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleRevert = () => {
    revertCallback?.();
  };

  const handleEdit = () => {
    setIsEditing(true);
    editCallback?.();
  };

  const handleRemove = () => {
    removeCallback?.();
  };

  const handleSave = () => {
    setIsEditing(false);
    saveCallback?.();
  };

  const handleCancel = () => {
    setIsEditing(false);
    cancelCallback?.();
  };

  const hiddenScoreContent = (
    <>
      <Chip label="Score hidden" />
      <div className="flex ml-auto">
        <TooltipIconButton
          color="success"
          icon={CachedIcon}
          tooltip="Revert"
          onClick={handleRevert}
        />
      </div>
    </>
  );

  const editingContent = (
    <>
      <input
        className="rounded-md w-16 text-center p-2"
        value={value}
        onChange={(event) => handleSetValue(event.target.value)}
      />
      <div className="flex ml-auto">
        <TooltipIconButton
          color="success"
          icon={CheckIcon}
          tooltip="Save"
          onClick={handleSave}
        />
        <TooltipIconButton
          color="error"
          icon={CloseIcon}
          tooltip="Cancel"
          onClick={handleCancel}
        />
      </div>
    </>
  );

  const normalStatusContent = (
    <div className="flex ml-auto">
      <TooltipIconButton
        icon={EditIcon}
        iconStyle={{ color: '#6366f1' }}
        tooltip="Edit"
        onClick={handleEdit}
      />
      <TooltipIconButton
        icon={DeleteIcon}
        iconStyle={{ color: '#94a3b8' }}
        tooltip="Remove"
        onClick={handleRemove}
      />
    </div>
  );

  return (
    <div
      className={`flex border rounded-lg p-3 items-center ${
        !isEditing && !hideScore ? 'justify-between' : ''
      } gap-2 mt-2`}
    >
      <div className="flex items-center">
        <p className="font-bold text-xl">{isTotal && 'Total '}Score:</p>
        {!hideScore && !isEditing && (
          <p className="text-xl ml-2">{isTotal ? value : currentPoints}</p>
        )}
      </div>
      {hideScore
        ? hiddenScoreContent
        : isEditing
          ? editingContent
          : normalStatusContent}
    </div>
  );
};

export default ScoreEditor;
