import React from 'react';
import { Rubric } from '../../../types';

type AssignmentRubricProps = {
  rubric: Rubric[];
};

const AssignmentRubric: React.FC<AssignmentRubricProps> = ({ rubric }) => (
  <div className="mt-2 overflow-hidden border border-gray-200 rounded-md">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="w-1/5 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
          >
            Name
          </th>
          <th
            scope="col"
            className="w-7/10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
          >
            Description
          </th>
          <th
            scope="col"
            className="w-1/10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
          >
            Points Possible
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {rubric.map((rubric) => (
          <tr key={rubric.name}>
            <td className="px-6 py-4">
              <div className="text-sm text-gray-900 break-words">
                {rubric.name}
              </div>
            </td>
            <td className="px-6 py-4">
              <div className="text-sm text-gray-900 break-words">
                {rubric.description}
              </div>
            </td>
            <td className="px-6 py-4">
              <div className="text-sm text-gray-900 break-words">
                {rubric.points_possible}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default AssignmentRubric;
