import { useQuery } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiProvider';
import { formatLengthField } from './organization';
import { PLATFORM_ROW_DETAIL_TYPE, API_ROW_DETAIL_TYPE } from '../constants';
import {
  AssignmentRow,
  UserWithAssignmentDetails,
  AssignmentRowDetail,
  GradingJob,
  Member,
  ApiAssignmentWithJob,
  ApiJob,
} from '../types';

interface OrganizationAssignments {
  data?: AssignmentRow[];
  rawData?: AssignmentRowDetail[];
}

interface AssignmentDataGridData extends OrganizationAssignments {
  isLoading: boolean;
  error: Error | null;
}

export const useAssignmentDataGridData = (
  memberList: Member[],
  apiUserId?: string
): AssignmentDataGridData => {
  const { api } = useApi();

  const getEvaluationTime = (job: GradingJob | ApiJob | null): string => {
    const start = job?.created_at;
    const end = job?.timestamp;

    const startDate: Date = start?.toDate() || new Date();
    const endDate: Date = end?.toDate() || new Date();

    const diffInMs: number = endDate.getTime() - startDate.getTime();

    const diffInSeconds: number = Math.floor(diffInMs / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;

    if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? 's' : ''} and ${seconds} sec${
        seconds > 1 ? 's' : ''
      }`;
    } else {
      return `${seconds} sec${seconds > 1 ? 's' : ''}`;
    }
  };

  const convertData = (data: UserWithAssignmentDetails[]): AssignmentRow[] => {
    return data
      .map(
        ({ firstName, lastName, email, assignments, metadata, gradingJob }) =>
          assignments.map(
            ({
              assignment_id,
              assignment_name,
              assignment_metadata_id,
              grading_job_id,
              submissions,
              created_at,
            }) => ({
              id: assignment_id,
              teacherName: `${firstName || ''} ${lastName || ''}`.trim() || '-',
              teacherEmail: email || '-',
              course: metadata[assignment_metadata_id]?.course || '-',
              assignmentName:
                assignment_name ||
                metadata[assignment_metadata_id]?.question_truncated ||
                '-',
              type: 'Platform',
              submissionCount: formatLengthField(
                submissions.length,
                'submission'
              ),
              evaluationTime: getEvaluationTime(gradingJob[grading_job_id]),
              createdAt: created_at.toDate(),
            })
          )
      )
      .flatMap((arr) => arr);
  };

  const convertApiData = (data: ApiAssignmentWithJob[]): AssignmentRow[] => {
    return data.map(
      ({ assignment_id, course, jobData, created_at }) =>
        ({
          id: assignment_id,
          teacherName: '-',
          teacherEmail: '-',
          course,
          assignmentName: 'API assignment',
          type: 'API',
          submissionCount: '-',
          evaluationTime: jobData ? getEvaluationTime(jobData) : '-',
          createdAt: created_at.toDate(),
        }) as AssignmentRow
    );
  };

  const fetchOrgAssignments =
    async (): Promise<OrganizationAssignments | null> => {
      try {
        const userDetails =
          await api.getUserWithAssignmentDetailsFromIds(memberList);
        const apiUserAssignments =
          await api.getApiAssignmentsByUserId(apiUserId);

        const convertedData = convertData(userDetails);
        const convertedApiData = convertApiData(apiUserAssignments);

        const initalValue = [] as AssignmentRowDetail[];
        const assignmentList = userDetails.reduce(
          (acc, { assignments }) => [
            ...acc,
            ...assignments.map(
              (assignment) =>
                ({
                  type: PLATFORM_ROW_DETAIL_TYPE,
                  data: assignment,
                }) as AssignmentRowDetail
            ),
          ],
          initalValue
        );
        const ApiAssignmentList = apiUserAssignments.map(
          (assignment) =>
            ({
              type: API_ROW_DETAIL_TYPE,
              data: assignment,
            }) as AssignmentRowDetail
        );

        return {
          data: [...convertedData, ...convertedApiData],
          rawData: [...assignmentList, ...ApiAssignmentList],
        };
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'An unknown error occurred.';
        console.error('Error fetching organization assignments:', errorMessage);
        throw error;
      }
    };

  const { data, isLoading, error } = useQuery<OrganizationAssignments | null>({
    queryKey: [
      'organizationAssignments',
      ...memberList.map(({ uid }) => uid),
      apiUserId,
    ],
    queryFn: fetchOrgAssignments,
  });

  return {
    data: data?.data,
    rawData: data?.rawData,
    isLoading,
    error: error as Error | null,
  };
};
