import { useQuery } from '@tanstack/react-query';

import {
  ASSIGNMENT_ERROR_STATUS,
  ASSIGNMENT_WARNING_STATUS,
  ASSIGNMENT_SUCCESS_STATUS,
  ASSIGNMENT_LOADING_STATUS,
} from '../constants';
import { FailedSubmissionDetail } from '../types';
import { useApi } from '../contexts/ApiProvider';

interface AssignmentStatusData {
  status: string;
  totalCount: number;
  failedCount: number;
}

interface AssignmentStatus {
  data?: AssignmentStatusData;
  isLoading: boolean;
  error: Error | null;
}

export const useAssignmentStatus = (assignmentId: string): AssignmentStatus => {
  const { api } = useApi();

  const fetchAssignmentStatus = async (
    assignmentId: string
  ): Promise<AssignmentStatusData> => {
    try {
      const currentUser = api.getCurrentUser();

      if (!currentUser) {
        throw new Error('Unauthenticated user!');
      }

      const { uid } = currentUser;

      const submissions =
        (await api.getSubmissionsByUserIdAndAssignmentId(uid, assignmentId)) ||
        [];
      const failedSubmissions = submissions.filter(
        (submission) => (submission as FailedSubmissionDetail).failed
      );

      const defaultData: AssignmentStatusData = {
        status: ASSIGNMENT_LOADING_STATUS,
        totalCount: submissions.length,
        failedCount: failedSubmissions.length,
      };

      if (
        !submissions.length ||
        failedSubmissions.length === submissions.length
      ) {
        return { ...defaultData, status: ASSIGNMENT_ERROR_STATUS };
      }

      return {
        status: failedSubmissions.length
          ? ASSIGNMENT_WARNING_STATUS
          : ASSIGNMENT_SUCCESS_STATUS,
        totalCount: submissions.length,
        failedCount: failedSubmissions.length,
      };
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred.';
      console.error(
        `Error fetching assignment submission status for ${assignmentId}: `,
        errorMessage
      );
      throw error;
    }
  };

  return useQuery<AssignmentStatusData>({
    queryKey: ['assignment_status', assignmentId],
    queryFn: () => fetchAssignmentStatus(assignmentId),
    enabled: !!assignmentId,
  });
};
