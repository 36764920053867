import React from 'react';

import { formatLengthField } from '../../../utils';
import { useTotalApiTokenCount } from '../../../utils/useTotalAPITokenCount';
import { totalTokens } from '../../../constants';
import { TotalTokenUsage } from '../../../types';

import Loading from '../../common/Loading';

interface OrganizationAssignmentDetailsProps {
  userId: string;
}

const OrganizationAssignmentDetails: React.FC<
  OrganizationAssignmentDetailsProps
> = ({ userId }) => {
  const { data, isLoading } = useTotalApiTokenCount(userId);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <></>;
  }

  return (
    <div className="text-base">
      {totalTokens.map(({ key, label }) => (
        <div key={key}>
          <span className="font-bold">{label}</span>
          <span>
            {formatLengthField(data[key as keyof TotalTokenUsage], 'token')}
          </span>
        </div>
      ))}
    </div>
  );
};

export default OrganizationAssignmentDetails;
