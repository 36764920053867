import React from 'react';
import ProfileHeader from './ProfileHeader';
import GeneralInformation from './GeneralInformation';
// import Assignments from './Assignments';
// import ActivityFeed from './ActivityFeed';
import Loading from '../common/Loading';
import useProfileData from '../../utils/useProfileData';
import { Container } from '../../dependencyInjection/Container';

interface ProfileCompositeProps {
  container: Container;
}

const ProfileComposite: React.FC<ProfileCompositeProps> = ({ container }) => {
  const { data, isLoading } = useProfileData(container);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className=" p-8">
      <ProfileHeader data={data} />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <GeneralInformation data={data} />
        {/* <Assignments data={data} /> */}
        {/* <ActivityFeed /> */}
      </div>
    </div>
  );
};

export default ProfileComposite;
