import React, { FC } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import Settings from './Settings';
import { Container } from '../../dependencyInjection/Container';

interface SettingsViewProps {
  container: Container;
}

const SettingsView: FC<SettingsViewProps> = ({ container }) => {
  return (
    <Dashboard
      currentNavigation="Settings"
      children={<Settings container={container} />}
    />
  );
};

export default SettingsView;
