import React, { FC, useState } from 'react';
import AssignmentForm from './AssignmentForm';
import RubricForm from './RubricForm';
import { Container } from '../../dependencyInjection/Container';
import AssignmentMetadataSelector from './AssignmentMetadataSelector';
import { scrollToTop } from '../../utils';
import { ManageNewSubmissionsView } from './ManageNewSubmissionsView';
import { useApi } from '../../contexts/ApiProvider';
import { GCAssignmentSelector } from './GCAssignmentSelector';

type AssignmentCreationPageProps = {
  container: Container;
  goBack?: () => void;
  postUpload: () => void;
};

const AssignmentCreationPage: FC<AssignmentCreationPageProps> = ({
  container,
  goBack,
  postUpload,
}) => {
  const { api, analyticsApi } = useApi();

  const [currentStep, setCurrentStep] = useState<
    'assignment' | 'rubric' | 'upload' | 'upload-direct'
  >('assignment');
  const userId = api.getCurrentUserId();

  const goToEditAssignment = () => scrollToTop();
  const goToRubricForm = () => {
    scrollToTop();
    setCurrentStep('rubric');
  };
  const goToUploadDocuments = () => {
    scrollToTop();
    setCurrentStep('upload');
  };
  const goToUploadDirectDocuments = () => {
    scrollToTop();
    setCurrentStep('upload-direct');
  };
  const goToAssignmentForm = () => {
    scrollToTop();
    analyticsApi.logCreateRubricPrevious(userId);
    setCurrentStep('assignment');
  };

  switch (currentStep) {
    case 'assignment':
      return (
        <div>
          <AssignmentForm
            submit={() => {
              goToRubricForm();
              analyticsApi.logCreateAssignment(userId);
            }}
            goBack={goBack}
          />
          <GCAssignmentSelector />
          <AssignmentMetadataSelector
            container={container}
            editCallback={goToEditAssignment}
            reuseCallback={goToUploadDirectDocuments}
          />
        </div>
      );
    case 'rubric':
      return (
        <RubricForm submit={goToUploadDocuments} goBack={goToAssignmentForm} />
      );
    case 'upload':
      return (
        <ManageNewSubmissionsView
          onClickPrevious={goToRubricForm}
          onCreateGradingJob={postUpload}
        />
      );
    case 'upload-direct':
      return (
        <ManageNewSubmissionsView
          onClickPrevious={goToAssignmentForm}
          onCreateGradingJob={postUpload}
        />
      );
    default:
      return null; // Handle unexpected steps
  }
};

export default AssignmentCreationPage;
