import React, { useEffect } from 'react';
import { FC } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ReviewDetailView from './views/review/ReviewDetailView';
import LogoutView from './views/logout/LogoutView';
import AssignmentsView from './views/assignments/AssignmentsView';
import OrganizationView from './views/Organization/OrganizationView';
import CreateAssignmentView from './views/assignments/CreateAssignmentView';
import { Container } from './dependencyInjection/Container';
import ProfileView from './views/profile/RootProfileView';
import SettingsView from './views/Settings/SettingsView';
import Loading from './components/common/Loading';
import OnboardingView from './views/Onboarding/OnboardingView';
import PrivacyPolicyView from './views/privacyPolicy/PrivacyPolicyView';
import TermsOfServiceView from './views/termsOfService/TermsOfServiceView';
import { SubmissionViewerView } from './views/viewer/submission/SubmissionViewerView';
import Contact from './views/Contact';
import About from './views/About';
import { GradingResultProvider } from './components/review/GradingResultContext';
import useOnboardingStatus from './utils/useOnboardingStatus';

type AuthenticatedRoutesProps = {
  container: Container;
};

export const AuthenticatedRoutes: FC<AuthenticatedRoutesProps> = ({
  container,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOnboarding, isExistingUser, error, isLoading } =
    useOnboardingStatus();

  useEffect(() => {
    if (isLoading || error || location.pathname !== '/') return;
    navigate(isOnboarding && !isExistingUser ? '/onboarding' : '/assignments');
  }, [isLoading, error, isOnboarding, isExistingUser, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Loading />} />
      <Route path="profile" element={<ProfileView container={container} />} />
      <Route path="settings" element={<SettingsView container={container} />} />
      <Route path="onboarding" element={<OnboardingView />} />
      <Route path="organization" element={<OrganizationView />} />
      <Route
        path="assignments"
        element={<AssignmentsView container={container} />}
      />
      <Route
        path="assignments/:id"
        element={
          <ReviewDetailView
            container={container}
            getAssignmentId={() => {
              return useParams<{ id: string }>().id!;
            }}
          />
        }
      />
      <Route path="logout" element={<LogoutView />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path="/create-assignment"
        element={<CreateAssignmentView container={container} />}
      />
      {/* <Route path="pricing" element={<PricingView />} /> */}
      <Route path="about" element={<About />} />
      <Route path="privacy" element={<PrivacyPolicyView />} />
      <Route path="terms" element={<TermsOfServiceView />} />
      <Route path="contact" element={<Contact />} />
      <Route
        path="export/:assignmentId/:submissionId"
        element={
          <GradingResultProvider>
            <SubmissionViewerView />
          </GradingResultProvider>
        }
      />
    </Routes>
  );
};

export default AuthenticatedRoutes;
