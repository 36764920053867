import { useQuery } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiProvider';
import { Organization } from '../types';

interface OrganizationData {
  data?: Organization | null;
  isLoading: boolean;
  error: Error | null;
}

export const useOrganizationData = (): OrganizationData => {
  const { api } = useApi();

  const fetchOrganizationData = async (): Promise<Organization | null> => {
    try {
      const currentUser = api.getCurrentUser();

      if (!currentUser) {
        throw new Error('Unauthenticated user!');
      }

      const { uid } = currentUser;

      const organizations = await api.getOrganizationsByAdminUserId(uid);
      return organizations.length ? organizations[0] : null;
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred.';
      console.error('Error fetching organization assignments:', errorMessage);
      throw error;
    }
  };

  const { data, isLoading, error } = useQuery<Organization | null>({
    queryKey: ['organizationData'],
    queryFn: fetchOrganizationData,
  });

  return { data, error, isLoading };
};
