import React, { useState, useRef, useEffect, useMemo } from 'react';
import chroma from 'chroma-js';

import { formatNumber } from '../../utils';
import { gradeDescriptorMapping } from '../../constants';

export interface ScoreSliderProps {
  currentPoints: number;
  pointsPossible: number;
  handleSetValue?: (value: string) => void;
  handleSetCurrentPoints: (value: number) => void;
  saveContent?: (content: string | boolean) => void;
  numberOfSteps: number;
  minimumPoints: number;
  offset?: number;
}

const ScoreSlider: React.FC<ScoreSliderProps> = ({
  currentPoints,
  pointsPossible,
  handleSetValue,
  handleSetCurrentPoints,
  saveContent,
  numberOfSteps,
  minimumPoints,
  offset = 0,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const currentPosRef = useRef<number>();

  const getColorScale = () => {
    return chroma
      .scale(['red', 'orange', 'green'])
      .domain([pointsPossible * 0.03, pointsPossible * 0.97]);
  };

  const calculateBounds = () => {
    const percentBounds = 0.1;
    const lowerBound = Math.max(
      currentPoints - offset - pointsPossible * percentBounds,
      minimumPoints
    );
    const upperBound = Math.min(
      currentPoints - offset + pointsPossible * percentBounds,
      pointsPossible
    );
    return { lowerBound, upperBound };
  };

  const startDrag = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDrag = (event: MouseEvent) => {
    if (!isDragging || !sliderRef.current) return;

    const sliderRect = sliderRef.current.getBoundingClientRect();
    const relativeX = event.clientX - sliderRect.left;
    const stepSize = pointsPossible / numberOfSteps;

    let stepIndex = Math.round((relativeX / sliderRect.width) * numberOfSteps);
    stepIndex = Math.max(minimumPoints, Math.min(numberOfSteps, stepIndex));
    currentPosRef.current = formatNumber(stepIndex * stepSize + offset);
    handleSetCurrentPoints(currentPosRef.current);
  };

  const stopDrag = () => {
    setIsDragging(false);
    if (saveContent) {
      saveContent(`${currentPosRef.current}`);
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', onDrag);
      window.addEventListener('mouseup', stopDrag);
    } else {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', stopDrag);
    }
    return () => {
      window.removeEventListener('mousemove', onDrag);
      window.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging]);

  const { gradient, lowerBoundPercent, upperBoundPercent } = useMemo(() => {
    if (handleSetValue) {
      handleSetValue(`${currentPoints}`);
    }

    const { lowerBound, upperBound } = calculateBounds();
    const colorScale = getColorScale();
    const lowerBoundColor = colorScale(lowerBound).css();
    const upperBoundColor = colorScale(upperBound).css();
    const gradient = `linear-gradient(90deg, ${lowerBoundColor}, ${upperBoundColor})`;

    const totalWidthPercent = 100;
    const lowerBoundPercent = (lowerBound / pointsPossible) * totalWidthPercent;
    const upperBoundPercent = (upperBound / pointsPossible) * totalWidthPercent;

    return {
      gradient,
      lowerBoundPercent,
      upperBoundPercent,
    };
  }, [currentPoints]);

  const renderTicks = () => {
    const tickInterval = Math.ceil(pointsPossible / 10);
    const ticks = Array.from(
      { length: Math.ceil(pointsPossible / tickInterval) + 1 },
      (_, i) => i * tickInterval
    );

    const renderingTicks = offset
      ? gradeDescriptorMapping.map((item) => item.descriptor)
      : ticks;

    return (
      <div className="relative flex justify-between">
        {renderingTicks.map((tick, index) => (
          <div key={index} className="text-xs text-center text-gray-500 px-4">
            <span>{tick}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        ref={sliderRef}
        className="relative h-4 flex items-center mt-4"
        onMouseDown={startDrag}
      >
        <div className="absolute top-1/4 left-0 h-1 w-full bg-gray-300 rounded-full transform -translate-y-1/2"></div>
        <div
          className="absolute top-1/4 h-4 rounded-full shadow border border-white-900 transform -translate-y-1/2"
          style={{
            left: `${lowerBoundPercent}%`,
            width: `${upperBoundPercent - lowerBoundPercent}%`,
            backgroundImage: gradient,
          }}
        ></div>
      </div>

      {renderTicks()}
    </div>
  );
};

export default ScoreSlider;
