import React, { useState, useEffect } from 'react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { Assignment } from '../../../types';
import { useAssignmentsContext } from '../../../contexts/AssignmentsContextProvider';

type AssignmentNameEditorProps = {
  assignment: Assignment;
  setIsEditing: (value: boolean) => void;
  updateLocalAssignment: (newName: string) => void;
};

const AssignmentNameEditor: React.FC<AssignmentNameEditorProps> = ({
  assignment,
  setIsEditing,
  updateLocalAssignment,
}) => {
  const { updateAssignmentName } = useAssignmentsContext();
  const [newAssignmentName, setNewAssignmentName] = useState(
    assignment.assignment_name
  );

  useEffect(() => {
    if (assignment) {
      setNewAssignmentName(
        assignment.assignment_name == undefined
          ? assignment.metadata_content.question
          : assignment.assignment_name
      );
    }
  }, [assignment]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAssignmentName(e.target.value);
  };

  const handleUpdateAssignmentName = async () => {
    if (assignment) {
      try {
        await updateAssignmentName(assignment.assignment_id, newAssignmentName);
        updateLocalAssignment(newAssignmentName);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating assignment:', error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleUpdateAssignmentName();
    }
  };

  return (
    <>
      <input
        type="text"
        value={newAssignmentName}
        onChange={handleInputChange}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleKeyDown}
        className="h-6 w-1/2 border border-gray-300 rounded text-sm"
      />
      <button
        onClick={(e) => {
          handleUpdateAssignmentName();
          e.stopPropagation();
        }}
        className="w-6 text-indigo-600 rounded"
      >
        <CheckCircleIcon />
      </button>
      <button
        onClick={(e) => {
          setIsEditing(false);
          e.stopPropagation();
        }}
        className="w-6 text-gray-500 rounded"
      >
        <XCircleIcon />
      </button>
    </>
  );
};

export default AssignmentNameEditor;
