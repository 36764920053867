import React, { FC } from 'react';

import Dashboard from '../../components/dashboard/Dashboard';
import Organization from './Orgnization';

const OrganizationView: FC = () => {
  return (
    <Dashboard currentNavigation="Organization" children={<Organization />} />
  );
};

export default OrganizationView;
