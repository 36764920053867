import moment from 'moment';

export const getFormattedDateTime = (date?: Date): string => {
  if (!date) return '';

  return moment(date).format('MM/DD/YYYY HH:mm:ss');
};

export const formatNumber = (number: number): number =>
  parseFloat(number.toFixed(2));

export const formatLengthField = <T>(
  length: number,
  fieldName: string
): string => {
  switch (length) {
    case 0:
      return `No ${fieldName}`;
    case 1:
      return `1 ${fieldName}`;
    default:
      return `${length} ${fieldName}s`;
  }
};
