import React, { useState, useMemo } from 'react';
import { Container } from '../../../dependencyInjection/Container';
import AssignmentMetadataInfoView from './AssignmentMetadataInfoView';
import { useAssignmentMetadataContentByPath } from '../../assignments/hooks/useAssignmentMetadataContentByPath';
import { useAssignmentMetadataInfoList } from '../../assignments/hooks/useAssignmentMetadataInfoList';
import { AssignmentMetadataInfo } from './AssignmentMetadataInfo';
import { useMetadataContentFromPath } from '../../../utils/useMetadataContentFromPath';

type AssignmentMetadataInfoListProps = {
  container: Container;
  reuseCallback: () => void;
  editCallback?: () => void;
};

const AssignmentMetadataInfoList: React.FC<AssignmentMetadataInfoListProps> = ({
  container,
  reuseCallback,
  editCallback,
}) => {
  const maybeAssignmentMetadataInfoList = useAssignmentMetadataInfoList(
    container.assignmentMetadataInfoListProvider
  );
  const [selectedId, setSelectedId] = useState('');
  const assignmentMetadataInfoList = maybeAssignmentMetadataInfoList
    ? maybeAssignmentMetadataInfoList.assignmentMetadataInfoList
    : undefined;

  const selectedInfo: AssignmentMetadataInfo | null = useMemo(() => {
    if (!setSelectedId) return null;

    const assignmentMetadataInfo = assignmentMetadataInfoList?.find(
      (info) => info.assignment_metadata_id === selectedId
    );
    if (!assignmentMetadataInfo) return null;

    return assignmentMetadataInfo;
  }, [selectedId]);

  const { data: selectedMetadataContent, isLoading } =
    useMetadataContentFromPath(
      container,
      selectedInfo?.assignment_metadata_path
    );

  const handleItemClick = (info: AssignmentMetadataInfo) => {
    setSelectedId((prev) =>
      prev === info.assignment_metadata_id ? '' : info.assignment_metadata_id
    );
  };

  if (!assignmentMetadataInfoList) {
    return <div>Loading...</div>;
  }

  return (
    <ul role="list" className="space-y-2">
      {assignmentMetadataInfoList.map((info) => (
        <li
          key={info.assignment_metadata_id}
          className={`owler-li-bubble owler-border-gray-lg ${
            selectedId === info.assignment_metadata_id
              ? 'bg-indigo-50 border-indigo-300'
              : 'hover:bg-gray-50'
          }`}
          onClick={() => handleItemClick(info)}
        >
          <AssignmentMetadataInfoView
            assignmentMetadataInfo={info}
            isSelected={selectedId === info.assignment_metadata_id}
            assignmentMetadataContent={selectedMetadataContent}
            isLoading={isLoading}
            reuseCallback={reuseCallback}
            editCallback={editCallback}
          />
        </li>
      ))}
    </ul>
  );
};

export default AssignmentMetadataInfoList;
