import React, { useState } from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  FormHelperText,
  InputLabel,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { departmentOptions } from '../../constants';

interface CustomSelectProps {
  fieldRegister: UseFormRegisterReturn;
  fieldError?: FieldError;
  label: string;
  id: string;
  defaultValue?: string;
  options: string[];
  includesCustom?: boolean;
  validateField: any;
  setFieldValue: any;
  isSubmitted: boolean;
  isEditing?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  fieldRegister,
  fieldError,
  label,
  id,
  defaultValue = '',
  options,
  includesCustom = false,
  validateField,
  setFieldValue,
  isSubmitted,
  isEditing = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isSelect, setIsSelect] = useState(
    !isEditing ||
      !includesCustom ||
      !defaultValue ||
      departmentOptions.includes(defaultValue)
  );
  const [autoFocus, setAutoFocus] = useState(false);

  const handleSelectChange = async (event: SelectChangeEvent) => {
    const initialValue = event.target.value as string;

    if (initialValue === 'Other') {
      setIsSelect(false);
      setAutoFocus(true);
    }

    const value = initialValue === 'Other' ? '' : initialValue;
    setFieldValue(id, value);
    setSelectedValue(value);

    validateField(id);
  };

  const handleCloseInput = () => {
    setIsSelect(true);
    validateField(id);
    setFieldValue(id, '');
    setSelectedValue('');
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      sx={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
      variant="outlined"
      error={!!fieldError && isSubmitted}
    >
      <InputLabel htmlFor={`outlined-${id}`}>{label}</InputLabel>
      {isSelect ? (
        <Select
          id={`outlined-${id}`}
          {...fieldRegister}
          value={selectedValue}
          onChange={handleSelectChange}
          label={label}
          MenuProps={MenuProps}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
          {includesCustom && (
            <MenuItem value="Other">Other (please specify)</MenuItem>
          )}
        </Select>
      ) : (
        <OutlinedInput
          id={`outlined-${id}`}
          defaultValue={defaultValue}
          {...fieldRegister}
          inputProps={{
            className: 'focus:ring-0',
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="close input" onClick={handleCloseInput}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
          label={label}
          autoFocus={autoFocus}
        />
      )}
      {fieldError && isSubmitted && (
        <FormHelperText error>{fieldError.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelect;
