import { FirebaseApp } from 'firebase/app';
import { FirebaseAssignmentProvider } from '../components/assignments/AssignmentList/AssignmentProvider';
import { FirebaseAssignmentMetadataInfoListProvider } from '../components/AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfoListProvider';
import { FirebaseAssignmentStatusProvider } from '../components/assignments/AssignmentList/AssignmentStatusProvider';
import { FirebaseAssignmentsProvider } from '../components/assignments/AssignmentList/AssignmentsProvider';
import { FirebaseAssignmentMetadataContentProvider } from '../components/AssignmentMetadata/AssignmentMetadataContentProvider';
import { FirebaseFileDownloader } from '../FirebaseFileDownloader';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { Container, ContainerItems } from './Container';
import { FirebaseSubmissionsProvider } from '../components/assignments/hooks/useSubmissions';

const createFirebaseContainerFactory = (
  firebaseApp: FirebaseApp
): Container => {
  const firestore = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);
  const userId = auth.currentUser!.uid;

  const assignmentsProvider = new FirebaseAssignmentsProvider(
    firestore,
    userId
  );
  const assignmentProvider = new FirebaseAssignmentProvider(
    new FirebaseFileDownloader(storage),
    assignmentsProvider,
    firestore,
    userId
  );
  const assignmentMetadataInfoListProvider =
    new FirebaseAssignmentMetadataInfoListProvider(firestore, userId);
  const assignmentStatusProvider = new FirebaseAssignmentStatusProvider(
    firestore,
    userId
  );
  const assignmentMetadataContentProvider =
    new FirebaseAssignmentMetadataContentProvider(
      new FirebaseFileDownloader(storage)
    );
  const submissionsProvider = new FirebaseSubmissionsProvider(
    storage,
    firestore,
    userId
  );

  const createUserAssignmentProvider = (uid: string): ContainerItems => {
    const assignmentsProvider = new FirebaseAssignmentsProvider(firestore, uid);
    const assignmentProvider = new FirebaseAssignmentProvider(
      new FirebaseFileDownloader(storage),
      assignmentsProvider
    );
    const assignmentMetadataInfoListProvider =
      new FirebaseAssignmentMetadataInfoListProvider(firestore, uid);
    const assignmentStatusProvider = new FirebaseAssignmentStatusProvider(
      firestore,
      uid
    );
    const assignmentMetadataContentProvider =
      new FirebaseAssignmentMetadataContentProvider(
        new FirebaseFileDownloader(storage)
      );
    const submissionsProvider = new FirebaseSubmissionsProvider(
      storage,
      firestore,
      uid
    );

    return {
      assignmentProvider,
      assignmentsProvider,
      assignmentMetadataInfoListProvider,
      assignmentStatusProvider,
      assignmentMetadataContentProvider,
      submissionsProvider,
    };
  };

  return {
    assignmentProvider,
    assignmentsProvider,
    assignmentMetadataInfoListProvider,
    assignmentStatusProvider,
    assignmentMetadataContentProvider,
    submissionsProvider,
    createUserAssignmentProvider,
  };
};

export default createFirebaseContainerFactory;
