import { z } from 'zod';

export const signupSchema = z.object({
  firstName: z.string().nonempty('First name is required'),
  lastName: z.string().nonempty('Last name is required'),
  email: z
    .string()
    .nonempty('Email is required')
    .email('Invalid email address'),
  password: z
    .string()
    .nonempty('Password is required')
    .min(12, 'Password must be at least 12 characters long.'),
  termsAccepted: z.boolean().refine((val) => val === true, {
    message: 'You must accept the terms and privacy policy.',
  }),
});

export type SignupForm = z.infer<typeof signupSchema>;
