import { z } from 'zod';

export const assignmentSchema = z.object({
  assignment_name: z.string().nonempty('Assignment name is required'),
  department: z.string().nonempty('Department is required'),
  teacher_type: z.string().nonempty('Teacher type is required'),
  school_type: z.string().nonempty('School type is required'),
  course: z.string().nonempty('Course is required'),
  grade_level: z.string().nonempty('Grade level is required'),
  question_background: z.string().nonempty('Essay background is required'),
  question: z.string().nonempty('Essay prompt is required'),
});

export type AssignmentForm = z.infer<typeof assignmentSchema>;
