import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../components/dashboard/Dashboard';
import { Container } from '../../dependencyInjection/Container';
import AssignmentCreationPage from '../../components/assignments/AssignmentCreationPage';
import { CurrentAssignmentProvider } from '../../contexts/CurrentAssignmentContext';
import { AssignmentsContextProvider } from '../../contexts/AssignmentsContextProvider';

type CreateAssignmentViewProps = {
  container: Container;
};

const CreateAssignmentView: FC<CreateAssignmentViewProps> = ({ container }) => {
  const navigate = useNavigate();
  return (
    <Dashboard
      currentNavigation="Create Assignment"
      children={
        <AssignmentsContextProvider container={container}>
          <CurrentAssignmentProvider>
            <div className="space-y-8">
              <div className="rounded-lg bg-white border shadow border-gray-200">
                <div className="px-4 py-5 sm:p-6">
                  <AssignmentCreationPage
                    container={container}
                    postUpload={() => navigate('/assignments')}
                  />
                </div>
              </div>
            </div>
          </CurrentAssignmentProvider>
        </AssignmentsContextProvider>
      }
    />
  );
};

export default CreateAssignmentView;
