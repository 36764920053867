import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/favicon.ico';

const ResetConfirmation: React.FC = () => {
  return (
    <div className="flex flex-col gap-4 mt-16 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="flex justify-center">
        <img
          src="/images/owler-logo.png/"
          className="w-10 md:w-12 l:w-16"
          alt="Owler AI Logo"
        />
      </div>
      <p className="text-center text-xl">Reset your password</p>
      <div className="flex flex-col gap-4 bg-white rounded-lg shadow-md px-8 py-4 w-full">
        <p>
          Check your email for a link to reset your password. If it doesn’t
          appear within a few minutes, check your spam folder.
        </p>
        <Link to="/login" className="w-full justify-center owler-indigo-button">
          Return to sign in
        </Link>
      </div>
    </div>
  );
};

export default ResetConfirmation;
