import React from 'react';
import { Stack } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import AssignmentMetadata from './AssignmentMetadata';
import SubmissionDetail from './SubmissionDetail';
import SubmissionDetailTable from './SubmissionDetailTable';
import ApiStudentResponse from './ApiStudentResponse';
import Loading from '../../common/Loading';
import { useAssignmentDetails } from '../../../utils/useAssignmentDetails';
import {
  API_ROW_DETAIL_TYPE,
  PLATFORM_ROW_DETAIL_TYPE,
} from '../../../constants';
import {
  AssignmentRowDetail,
  AssignmentMetadataContent,
  ApiAssignmentWithJob,
  Rubric,
} from '../../../types';

interface AssignmentDetailProps {
  data?: AssignmentRowDetail;
}

const AssignmentDetail: React.FC<AssignmentDetailProps> = ({ data }) => {
  const { type, data: rowDetail } = data || {};
  const { data: assignmentDetail, isLoading } = useAssignmentDetails(
    rowDetail?.userId,
    rowDetail?.assignment_id,
    type
  );

  const apiRef = useGridApiContext();
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions!.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions!.viewportInnerSize.width);
  }, [apiRef]);

  React.useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange
    );
  }, [apiRef, handleViewportInnerSizeChange]);

  const metadata =
    type === PLATFORM_ROW_DETAIL_TYPE
      ? assignmentDetail?.metadata
      : (rowDetail as unknown as AssignmentMetadataContent);

  const rubrics =
    type === PLATFORM_ROW_DETAIL_TYPE
      ? assignmentDetail?.metadata?.rubric
      : (rowDetail as ApiAssignmentWithJob).rubric_components.map(
          (item, index) =>
            ({
              ...item,
              id: `${index}`,
            }) as Rubric
        );

  const questions =
    (type === API_ROW_DETAIL_TYPE &&
      (rowDetail as ApiAssignmentWithJob).questions) ||
    [];
  const answers =
    (type === API_ROW_DETAIL_TYPE &&
      (rowDetail as ApiAssignmentWithJob).student_answers) ||
    [];
  const explanation =
    (type === API_ROW_DETAIL_TYPE &&
      (rowDetail as ApiAssignmentWithJob).jobData?.gradingOutput.explanation) ||
    '-';

  return (
    <Stack
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        width,
      }}
      direction="column"
    >
      <div className="flex flex-col gap-4 px-16 py-4">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <h1 className="text-3xl font-bold">Assignment Details</h1>
            <AssignmentMetadata metadata={metadata} type={type} />

            {type === API_ROW_DETAIL_TYPE && (
              <ApiStudentResponse
                questions={questions}
                answers={answers}
                explanation={explanation}
              />
            )}

            {type === PLATFORM_ROW_DETAIL_TYPE && (
              <div className="flex flex-col gap-4">
                <SubmissionDetail
                  userId={rowDetail?.userId}
                  assignmentId={rowDetail?.assignment_id}
                  submissions={assignmentDetail?.submissions}
                  rubrics={assignmentDetail?.metadata?.rubric}
                />
                <SubmissionDetailTable rubrics={rubrics} />
              </div>
            )}
          </>
        )}
      </div>
    </Stack>
  );
};

export default AssignmentDetail;
