import React from 'react';
import { IconButton, Menu, MenuItem, Divider } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { useAssignmentsContext } from '../../../contexts/AssignmentsContextProvider';
import { useCurrentAssignment } from '../../../contexts/CurrentAssignmentContext';
import { Assignment } from '../../../types';

type AssignmentSettingsProps = {
  assignment: Assignment;
  renameCallback: () => void;
  editCallback: () => void;
  reuseCallback: () => void;
};

const AssignmentSettings: React.FC<AssignmentSettingsProps> = ({
  assignment,
  renameCallback,
  editCallback,
  reuseCallback,
}) => {
  const { updateAssignmentArchived } = useAssignmentsContext();
  const { setCurrentAssignment, setOriginalAssignmentMetadataContent } =
    useCurrentAssignment();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClicked = (
    event: React.MouseEvent,
    callback?: () => void
  ) => {
    if (!callback) return;
    setCurrentAssignment(assignment);
    setOriginalAssignmentMetadataContent(assignment.metadata_content);
    callback();
    handleClose(event);
  };

  const handleArchive = async () => {
    if (assignment.isArchived) return;
    await updateAssignmentArchived(assignment.assignment_id);
  };

  const menuItems = [
    { type: 'menu', label: 'Rename Assignment', callback: renameCallback },
    { type: 'menu', label: 'Archive Assignment', callback: handleArchive },
    { type: 'divider', label: 'divider' },
    { type: 'menu', label: 'Duplicate & Edit', callback: editCallback },
    { type: 'menu', label: 'Reuse Assignment', callback: reuseCallback },
  ];

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.map(({ type, label, callback }) =>
          type === 'divider' ? (
            <Divider key={label} />
          ) : (
            <MenuItem
              key={label}
              onClick={(event) => handleMenuClicked(event, callback)}
            >
              {label}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default AssignmentSettings;
