import React, { useState } from 'react';
import { TextEditor } from './TextEditor';
import AddBulletPointButton from './AddBulletPointButton';
import DeleteBulletPointButton from './DeleteBulletPointButton';

interface UnorderedListTextEditorProps {
  saveContent: (contents: string[]) => void;
  initialContent: string[];
}

const UnorderedListTextEditor: React.FC<UnorderedListTextEditorProps> = ({
  saveContent,
  initialContent,
}) => {
  const [contents, setContents] = useState(initialContent);

  const updateAndSave = (updatedContents: string[]) => {
    setContents(updatedContents); // Update the local state for rendering
    saveContent(updatedContents); // Save externally
  };

  const handleContentChange = (text: string, index: number) => {
    const updatedContents = [...contents];
    updatedContents[index] = text;
    updateAndSave(updatedContents);
  };

  const handleDelete = (index: number) => {
    const updatedContents = contents.filter((_, i) => i !== index);
    updateAndSave(updatedContents);
  };

  const handleAdd = () => {
    const updatedContents = [...contents, ' '];
    updateAndSave(updatedContents);
  };

  return (
    <div>
      <ul>
        {contents.map((content, index) => (
          <li key={index} className="mb-1">
            <div className="flex items-center">
              <div className="w-full">
                <TextEditor
                  initialContent={content}
                  saveContent={(content) => handleContentChange(content, index)}
                />
              </div>
              <div className="mr-2 w-8 h-8">
                <DeleteBulletPointButton onDelete={() => handleDelete(index)} />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-2">
        <AddBulletPointButton onAdd={handleAdd} />
      </div>
    </div>
  );
};

export { UnorderedListTextEditor };
