import React, { useState, useMemo } from 'react';

import ScoreSlider from './ScoreSlider';
import { gradeDescriptorMapping } from '../../constants';

export interface QualitativePointRangeProps {
  pointsEarned: number;
  pointsPossible: number;
  saveContent?: (content: string) => void;
  minimumPoints?: number;
  numberOfSteps?: number;
  offset?: number;
}

const QualitativePointRange: React.FC<QualitativePointRangeProps> = ({
  pointsEarned,
  pointsPossible,
  saveContent,
  minimumPoints = 0,
  numberOfSteps = 1,
  offset = 0,
}) => {
  const [currentPoints, setCurrentPoints] = useState(pointsEarned);

  const totalDescription = useMemo(() => {
    const estimatePoints = Math.max(currentPoints - offset, 0);
    return (
      gradeDescriptorMapping.find(
        (item) =>
          item.range[0] <= estimatePoints && item.range[1] > estimatePoints
      )?.descriptor || ''
    );
  }, [currentPoints]);

  const handleSetCurrentPoints = (newValue: number) => {
    setCurrentPoints(newValue);
  };

  const handleSaveContent = (content: string | boolean) => {
    if (typeof content === 'string' && saveContent) {
      saveContent(content);
    }
  };

  return (
    <div>
      <div className="mt-4 h-9">
        <span className="font-bold underline text-lg">
          Overall Performance Estimate
        </span>
      </div>
      <ScoreSlider
        currentPoints={currentPoints}
        pointsPossible={pointsPossible}
        handleSetCurrentPoints={handleSetCurrentPoints}
        saveContent={handleSaveContent}
        numberOfSteps={numberOfSteps}
        minimumPoints={minimumPoints}
        offset={offset}
      />
    </div>
  );
};

export default QualitativePointRange;
