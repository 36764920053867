import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import TooltipIconButton from '../../common/TooltipIconButton';
import { convertGradedFilePathToFileName } from '../../../utils';
import { AssignmentSubmission } from '../../../types';
import { Rubric, SuccessfulSubmissionDetail } from '../../../types';

interface SubmissionDetailProps {
  userId?: string;
  assignmentId?: string;
  submissions?: AssignmentSubmission[] | null;
  rubrics?: Rubric[];
}

const SubmissionDetail: React.FC<SubmissionDetailProps> = ({
  userId,
  assignmentId,
  submissions,
  rubrics,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!assignmentId || !submissions || !submissions.length || !rubrics) {
    return null;
  }

  const submissionCount = submissions.length ?? 0;
  const currentSubmission = submissions[selectedIndex];
  const submissionLink = `/assignments/${assignmentId}?userId=${userId}&id=${currentSubmission.id}`;

  const handleNext = () => {
    setSelectedIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return newIndex < submissionCount ? prevIndex + 1 : prevIndex;
    });
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex >= 0 ? newIndex : prevIndex;
    });
  };
  return (
    <div className="flex justify-between">
      <TooltipIconButton
        tooltip="Previous"
        icon={ArrowBackIosNewIcon}
        onClick={handlePrev}
        disabled={selectedIndex === 0}
      />
      <div className="flex flex-col items-center justify-center">
        <Link to={submissionLink}>
          <h1 className="text-xl font-bold text-blue-600 hover:text-blue-800">
            Submission #{selectedIndex + 1}
          </h1>
        </Link>
        <span className="text-md text-center">
          {convertGradedFilePathToFileName(
            (currentSubmission as SuccessfulSubmissionDetail).submission_path ||
              ''
          )}
        </span>
      </div>
      <TooltipIconButton
        tooltip="Next"
        icon={ArrowForwardIosIcon}
        onClick={handleNext}
        disabled={selectedIndex === submissionCount - 1}
      />
    </div>
  );
};

export default SubmissionDetail;
