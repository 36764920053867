import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FirebaseError } from 'firebase/app';

import TextInput from '../../common/TextInput';
import { useNotification } from '../../../contexts/NotificationProvider';
import { useApi } from '../../../contexts/ApiProvider';
import { Profile } from '../../../models/user';
import { getFirebaseAuthErrorMessage, objectIncludes } from '../../../utils';
import {
  basicInfoSettingSchema,
  BasicInfoSettingForm,
} from '../../../validation';

interface BasicInfoFormProps {
  defaultData?: Profile;
}

const defaultValue: Profile = {
  firstName: '',
  lastName: '',
  numberOfStudents: 0,
  numberOfClasses: 0,
};

const BasicInfoForm: React.FC<BasicInfoFormProps> = ({
  defaultData = defaultValue,
}) => {
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { userId, firstName, lastName, numberOfStudents, numberOfClasses } =
    defaultData;

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<BasicInfoSettingForm>({
    resolver: zodResolver(basicInfoSettingSchema),
  });

  const onSubmit = async (data: BasicInfoSettingForm) => {
    if (objectIncludes(defaultData, data)) {
      return;
    }
    try {
      setIsLoading(true);
      await api.updateUserDocument(userId!, data);
    } catch (error) {
      console.error('Error updating account: ', error);
      const errorMessage = getFirebaseAuthErrorMessage(error as FirebaseError);
      showNotification(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 w-full">
          <TextInput
            id="firstName"
            label="First Name"
            defaultValue={firstName}
            fieldRegister={register('firstName')}
            fieldError={errors.firstName}
            isSubmitted={isSubmitted}
          />
          <TextInput
            id="lastName"
            label="Last Name"
            defaultValue={lastName}
            fieldRegister={register('lastName')}
            fieldError={errors.lastName}
            isSubmitted={isSubmitted}
          />
        </div>
        <div className="flex gap-4 w-full">
          <TextInput
            id="numberOfStudents"
            label="Number Of Students"
            defaultValue={numberOfStudents}
            fieldRegister={register('numberOfStudents', {
              setValueAs: (v) => (v === '' ? undefined : Number(v)),
            })}
            fieldError={errors.numberOfStudents}
            isSubmitted={isSubmitted}
            isNumber
          />
          <TextInput
            id="numberOfClasses"
            label="Number of Classes"
            defaultValue={numberOfClasses}
            fieldRegister={register('numberOfClasses', {
              setValueAs: (v) => (v === '' ? undefined : Number(v)),
            })}
            fieldError={errors.numberOfClasses}
            isSubmitted={isSubmitted}
            isNumber
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className={`owler-indigo-button ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isLoading}
          aria-disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Save changes'}
        </button>
      </div>
    </form>
  );
};

export default BasicInfoForm;
