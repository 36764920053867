import {
  PermIdentity as PermIdentityIcon,
  School as SchoolIcon,
} from '@mui/icons-material';

export const settingsTabLabels = ['Account'];

export const BASIC_INFO_CARD_KEY = 'basic-info';
export const SCHOOL_INFO_CARD_KEY = 'school-info';

export const sidebarItems = [
  {
    key: BASIC_INFO_CARD_KEY,
    label: 'Basic Information',
    icon: PermIdentityIcon,
  },
  {
    key: SCHOOL_INFO_CARD_KEY,
    label: 'School Information',
    icon: SchoolIcon,
  },
];
