import React, { useMemo, ReactElement } from 'react';
import { LightTooltip, HtmlTooltip } from '../common/StyledTooltips';
import { RubricGrading, GrammarMistake } from '../../types';
import { Highlight, generateHighlights } from './reviewUtils';

function generateElementsFromHighlights(
  submission: string,
  highlights: Highlight[]
): ReactElement[] {
  const elements: ReactElement[] = [];
  let currentIndex = 0;

  highlights.sort((a, b) => a.index - b.index || a.length - b.length);

  const groupedHighlights = highlights.reduce(
    (acc, highlight) => {
      const key = `${highlight.index}-${highlight.length}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(highlight);
      return acc;
    },
    {} as { [key: string]: Highlight[] }
  );

  Object.keys(groupedHighlights).forEach((key) => {
    const [index, length] = key.split('-').map(Number);
    const group = groupedHighlights[key];

    if (index > currentIndex) {
      const plainText = submission.slice(currentIndex, index);
      addTextWithLineBreaks(elements, plainText, currentIndex);
    }

    const styles = group.map((g) => g.color).join(' ');
    const title = group
      .map((g) => g.rubricName)
      .filter(Boolean)
      .join(', ');

    const highlightedText = submission.slice(index, index + length);
    if (styles.includes('underline')) {
      const grammarHighlight = group.find((item) =>
        item.color.includes('underline')
      );
      elements.push(
        <HtmlTooltip
          key={key}
          title={
            <div>
              <h1 className="text-lg font-bold text-blue-500">
                Grammar mistake
              </h1>
              <p className="text-base text-red-500 mt-2">
                {grammarHighlight?.explanation}
              </p>
              <p className="text-base text-green-500 mt-2">
                Here's the correction: {grammarHighlight?.correction}
              </p>
            </div>
          }
        >
          <span className={styles}>{highlightedText}</span>
        </HtmlTooltip>
      );
    } else {
      elements.push(
        <LightTooltip key={key} title={title}>
          <span className={styles}>{highlightedText}</span>
        </LightTooltip>
      );
    }

    currentIndex = index + length;
  });

  if (currentIndex < submission.length) {
    const remainingText = submission.slice(currentIndex);
    addTextWithLineBreaks(elements, remainingText, currentIndex);
  }

  return elements;
}

function addTextWithLineBreaks(
  elements: ReactElement[],
  text: string,
  key: number
) {
  const parts = text.split(/\r\n|\n|\r/);
  parts.forEach((part, index) => {
    elements.push(<span key={`${key}-${index}`}>{part}</span>);
    if (index < parts.length - 1) {
      elements.push(<br key={`${key}-${index}-br`} />);
    }
  });
}

interface ReviewStudentSubmissionProps {
  filename: string;
  studentSubmission: string;
  rubricGrading: RubricGrading;
  grammarMistakes: GrammarMistake[];
}

const ReviewStudentSubmission: React.FC<ReviewStudentSubmissionProps> = ({
  filename,
  studentSubmission,
  rubricGrading,
  grammarMistakes,
}) => {
  const highlights = useMemo(
    () => generateHighlights(studentSubmission, rubricGrading, grammarMistakes),
    [studentSubmission, rubricGrading]
  );
  const highlightedElements = useMemo(
    () => generateElementsFromHighlights(studentSubmission, highlights),
    [studentSubmission, highlights]
  );

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <h2 className="text-3xl my-4">File: {filename}</h2>
        <p className="leading-7 mb-4">{highlightedElements}</p>
      </div>
    </div>
  );
};

export default ReviewStudentSubmission;
