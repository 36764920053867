import React from 'react';

import CardContent from '../../components/common/ContentCard';
import OrganizationAssignments from '../../components/Organization/assignments/OrganizationAssignments';
import { useAssignmentDataGridData } from '../../utils/useAssignmentDataGridData';
import { Organization } from '../../types';

interface OrgAssignmentsProps {
  orgData: Organization;
}

const OrgAssignments: React.FC<OrgAssignmentsProps> = ({ orgData }) => {
  const { data, rawData, isLoading } = useAssignmentDataGridData(
    orgData.members,
    orgData.apiUserId
  );

  return (
    <CardContent
      children={
        <OrganizationAssignments
          rows={data}
          rawData={rawData}
          apiUserId={orgData.apiUserId}
          isLoading={isLoading}
        />
      }
      title="Assignments"
    />
  );
};

export default OrgAssignments;
