import React from 'react';
import { Assignment } from '../../../types';
import AssignmentRubric from './AssignmentRubric';
import AssignmentSelectionButtons from './AssignmentSelectionButtons';
import {
  useSubmissionScores,
  SubmissionScore,
} from '../hooks/useSubmissionScores';
import { assignmentScores } from '../../../constants';
import { Container } from '../../../dependencyInjection/Container';

type AssignmentDetailsProps = {
  container: Container;
  assignment: Assignment;
  editCallback: () => void;
  reuseCallback: () => void;
};

const AssignmentDetails: React.FC<AssignmentDetailsProps> = ({
  container,
  assignment,
  editCallback,
  reuseCallback,
}) => {
  const scoreData = useSubmissionScores(
    container.submissionsProvider,
    assignment
  );

  return (
    <div className="mt-4 text-gray-800">
      <p className="mt-1 text-sm">
        <span className="font-bold">Name:</span> {assignment.assignment_name}
      </p>
      <p className="mt-1 text-sm">
        <span className="font-bold">Department:</span>{' '}
        {assignment.metadata_content.department}
      </p>
      <p className="mt-1 text-sm">
        <span className="font-bold">Course:</span>{' '}
        {assignment.metadata_content.course}
      </p>
      <p className="mt-1 text-sm">
        <span className="font-bold">Teacher Type:</span>{' '}
        {assignment.metadata_content.teacher_type}
      </p>
      {assignment.metadata_content.grade_level && (
        <p className="mt-1 text-sm">
          <span className="font-bold">Grade Level:</span>{' '}
          {assignment.metadata_content.grade_level}
        </p>
      )}
      <p className="mt-1 text-sm">
        <span className="font-bold">Question:</span>{' '}
        {assignment.metadata_content.question}
      </p>
      <p className="mt-1 text-sm">
        <span className="font-bold">Question background:</span>{' '}
        {assignment.metadata_content.question_background}
      </p>
      <p className="mt-1 text-sm">
        <div className="flex gap-8">
          {assignmentScores.map(({ key, label }) => (
            <div key={key}>
              <span className="font-bold">{label}: </span>
              {scoreData.isLoading
                ? '...'
                : `${scoreData[key as keyof SubmissionScore] ?? ''}`}
            </div>
          ))}
        </div>
      </p>
      <AssignmentRubric rubric={assignment.metadata_content.rubric} />
      <AssignmentSelectionButtons
        assignment={assignment}
        editCallback={editCallback}
        reuseCallback={reuseCallback}
      />
    </div>
  );
};

export default AssignmentDetails;
