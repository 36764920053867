import { z } from 'zod';

export const accountDetailsSchema = z.object({
  schoolName: z.string().nonempty('School name is required'),
  department: z.string().nonempty('Department is required'),
  classes: z
    .array(z.string().min(1, 'Subject cannot be empty'))
    .nonempty('Must have at least one subject'),
  typeOfSchool: z.string().nonempty('Type of school is required'),
  city: z.string().nonempty('City is required'),
  state: z.string().nonempty('State is required'),
  zip: z.string().nonempty('ZIP code is required'),
});

export type AccountDetailsForm = z.infer<typeof accountDetailsSchema>;
