import React from 'react';
import { Warning as WarningIcon } from '@mui/icons-material';

import { LightTooltip } from '../../common/StyledTooltips';
import { GradingStatus } from './GradingStatus';
import { GradingStatuses } from './GradingStatuses';
import {
  ASSIGNMENT_ERROR_STATUS,
  ASSIGNMENT_SUCCESS_STATUS,
  ASSIGNMENT_WARNING_STATUS,
  progressingColor,
  loadingColor,
  errorColor,
  successColor,
  progressingText,
  loadingText,
  errorText,
  successText,
} from '../../../constants';

type AssignmentStatusViewProps = {
  gradingStatus?: GradingStatus;
  submissionStatus?: string;
  totalCount?: number;
  failedCount?: number;
};

const AssignmentStatusView: React.FC<AssignmentStatusViewProps> = ({
  gradingStatus,
  submissionStatus,
  totalCount = 0,
  failedCount = 0,
}) => {
  if (!gradingStatus || !submissionStatus) {
    return <></>;
  }

  const classes = [
    'rounded-md',
    'whitespace-nowrap',
    'mt-0.5',
    'px-1.5',
    'py-0.5',
    'text-xs',
    'font-medium',
    'ring-1',
    'ring-inset',
    'cursor-pointer',
  ].join(' ');

  const getCurrentStatus = () => {
    if (gradingStatus.jobStatus === GradingStatuses.InProgress) {
      return {
        color: progressingColor,
        status: progressingText,
        tooltipTitle: 'In progress',
      };
    }
    switch (submissionStatus) {
      case ASSIGNMENT_ERROR_STATUS:
        return {
          color: errorColor,
          status: errorText,
          tooltipTitle: `All submissions failed`,
        };
      case ASSIGNMENT_WARNING_STATUS:
        return {
          color: successColor,
          status: successText,
          tooltipTitle: `${failedCount} of ${totalCount} submissions failed`,
        };
      case ASSIGNMENT_SUCCESS_STATUS:
        return {
          color: successColor,
          status: successText,
          tooltipTitle: `All submissions succeeded`,
        };
      default:
        return {
          color: loadingColor,
          status: loadingText,
          tooltipTitle: 'Loading',
        };
    }
  };

  const { color, status, tooltipTitle } = getCurrentStatus();

  return (
    <LightTooltip title={tooltipTitle}>
      <div className={`flex items-center gap-1 ${color} ${classes}`}>
        <p>{status}</p>
        {submissionStatus === ASSIGNMENT_WARNING_STATUS && (
          <WarningIcon color="warning" fontSize="small" />
        )}
      </div>
    </LightTooltip>
  );
};

export default AssignmentStatusView;
