import { useState, useEffect } from 'react';
import { useApi } from '../contexts/ApiProvider';

interface OnboardingStatus {
  isOnboarding: boolean;
  isExistingUser: boolean;
  error: string;
  isLoading: boolean;
}

const useOnboardingStatus = (): OnboardingStatus => {
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { api } = useApi();

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      setIsLoading(true);

      try {
        const data = await api.getCurrentUserData();

        if (!data) {
          setIsExistingUser(true);
          setIsOnboarding(false);
          return;
        }

        const {
          userId,
          email,
          firstName,
          lastName,
          schoolName,
          department,
          classes,
          typeOfSchool,
          city,
          state,
          zip,
        } = data;
        const isMissingSignup = !userId && !email && !firstName && !lastName;
        const isMissingInfo =
          !schoolName ||
          !department ||
          !typeOfSchool ||
          !classes ||
          !classes.length ||
          !city ||
          !state ||
          !zip;

        setIsExistingUser(isMissingSignup);
        setIsOnboarding(isMissingInfo);
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'An unknown error occurred.';
        setError(errorMessage);
        console.error('Error fetching onboarding status:', errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOnboardingStatus();
  }, [api]);

  return { isOnboarding, isExistingUser, error, isLoading };
};

export default useOnboardingStatus;
