// Function to retrieve valid URL for Cloud Functions based on environment
export function getValidEnvUrl(usage: string): string {
  // Define the base URLs for production/preview and development environments
  const BASE_API_URL = `/api/v1/${usage}`;
  const DEV_API_URL = `/api-dev/v1/${usage}`;
  const isProdOrPreviewEnv =
    import.meta.env.VITE_ENV === 'production' ||
    import.meta.env.VITE_ENV === 'preview';

  // Return production/preview URL if in production or preview environment
  if (isProdOrPreviewEnv) {
    return BASE_API_URL;
  }

  // Check if localhost URLs should be used in development
  const enableLocalhost =
    import.meta.env.VITE_ENABLE_LOCALHOST_CLOUD_FUNCTIONS === 'true';

  if (enableLocalhost) {
    // Return localhost URLs for specific usages
    switch (usage) {
      case 'fetch_data':
        return 'http://localhost:8080';
      case 'queue':
        return 'http://localhost:8081';
      case 'oauth2authorize':
        return 'http://localhost:8082';
      case 'import_courses':
        return 'http://localhost:8083';
      case 'convert_and_grade':
        return 'http://localhost:8084';
      case 'import_submissions':
        return 'http://localhost:8085';
      default:
        // For all other usages, return the development API URL
        return DEV_API_URL;
    }
  } else {
    // Return the development API URL if localhost URLs are not enabled
    return DEV_API_URL;
  }
}
