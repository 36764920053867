export const progressingColor =
  'text-yellow-600 bg-yellow-50 ring-yellow-500/10';
export const loadingColor = 'text-yellow-600 bg-yellow-50 ring-yellow-500/10';
export const errorColor = 'text-red-800 bg-red-50 ring-red-600/20';
export const successColor = 'text-green-700 bg-green-50 ring-green-600/20';

export const progressingText = 'In progress';
export const loadingText = 'Loading';
export const errorText = 'Error';
export const successText = 'Completed';
