import React from 'react';

interface SubmissionNotFoundProps {
  fileName: string;
  error: string;
}

const SubmissionNotFound: React.FC<SubmissionNotFoundProps> = ({
  fileName,
  error,
}) => {
  const transcribeError = (
    <span>
      Please ensure (1) document is legible, and (2) one PDF file has been
      uploaded per student submission
    </span>
  );

  const errorMessage = error.includes('transcribe') ? transcribeError : '';

  return (
    <div className="flex items-center justify-center min-h-[80%] bg-gray-100">
      <div className="text-center bg-white p-8 rounded-lg shadow-md">
        <div className="text-2xl font-semibold text-gray-900 mb-4">
          Not Found for file <span className="underline">{fileName}</span>
        </div>
        <div className="text-base text-gray-600">{errorMessage}</div>
      </div>
    </div>
  );
};

export default SubmissionNotFound;
