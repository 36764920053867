import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AccountDetailsForm from '../../components/Onboarding/AccountDetailsForm';
import useOnboardingStatus from '../../utils/useOnboardingStatus';
import Loading from '../../components/common/Loading';

const OnboardingView: React.FC = () => {
  const navigate = useNavigate();
  const { isOnboarding, error, isLoading } = useOnboardingStatus();

  useEffect(() => {
    if (isLoading || error || isOnboarding) return;
    navigate('/assignments');
  }, [isLoading, error, isOnboarding, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isOnboarding) {
    return null;
  }

  return (
    <div className="flex min-h-full flex-1 flex-col gap-8 justify-center px-6 py-16 lg:px-8 sm:mx-auto sm:w-full sm:max-w-4xl">
      <div className="flex flex-col justify-end gap-4 w-full px-8 relative">
        <h2 className="text-gray-700 text-2xl font-medium absolute left-1/2 transform -translate-x-1/2">
          Getting to Know You
        </h2>

        <div className="ml-auto">
          <Link to="/assignments">
            <span className="text-sm font-bold text-blue-600">
              {`Skip for this time >`}
            </span>
          </Link>
        </div>
      </div>

      <div className="w-full border-b border-gray-300" />

      <div className="w-full px-8">
        <h2 className="text-gray-800 text-xl font-medium mt-4">
          Where do you teach?
        </h2>
        <AccountDetailsForm />
      </div>
    </div>
  );
};

export default OnboardingView;
