import { AssignmentScoreLabel } from '../types';

export const assignmentScores: AssignmentScoreLabel[] = [
  {
    key: 'mean',
    label: 'Mean',
  },
  {
    key: 'median',
    label: 'Median',
  },
  {
    key: 'mode',
    label: 'Mode',
  },
];

export const ASSIGNMENT_ERROR_STATUS = 'Error';
export const ASSIGNMENT_WARNING_STATUS = 'Warning';
export const ASSIGNMENT_SUCCESS_STATUS = 'Success';
export const ASSIGNMENT_LOADING_STATUS = 'Loading';
