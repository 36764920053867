import React from 'react';

import AccountDetailsForm from '../../Onboarding/AccountDetailsForm';
import SettingCard from '../../common/ContentCard';
import { Profile } from '../../../models/user';

interface SchoolInfoCardProps {
  data: Profile;
  isLoading: boolean;
}

const SchoolInfoCard: React.FC<SchoolInfoCardProps> = ({ data, isLoading }) => {
  return (
    <SettingCard
      children={<AccountDetailsForm defaultData={data} isEditing />}
      title="School Information"
    />
  );
};

export default SchoolInfoCard;
