import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container } from '../../dependencyInjection/Container';
import { scrollToTop } from '../../utils';

import AssignmentCreationPage from './AssignmentCreationPage';
import AssignmentList from './AssignmentList';
import { ManageNewSubmissionsView } from './ManageNewSubmissionsView';
import { useApi } from '../../contexts/ApiProvider';
import { useAssignmentsContext } from '../../contexts/AssignmentsContextProvider';
import { useCurrentAssignment } from '../../contexts/CurrentAssignmentContext';

type AssignmentPageProps = {
  container: Container;
};

const AssignmentPage: React.FC<AssignmentPageProps> = ({ container }) => {
  const navigate = useNavigate();
  const { api, analyticsApi } = useApi();

  const [currentStep, setCurrentStep] = useState<
    'assignmentList' | 'createAssignment' | 'upload'
  >('assignmentList');

  const { refreshAssignments } = useAssignmentsContext();
  const { resetCurrentAssignment } = useCurrentAssignment();

  const handleCreateAssignmentClick = () => {
    resetCurrentAssignment();
    navigate('/create-assignment');
  };

  const goToAssignmentList = () => {
    scrollToTop();
    setCurrentStep('assignmentList');
  };
  const goToCreateAssignment = () => {
    scrollToTop();
    setCurrentStep('createAssignment');
  };
  const goToUploadDocuments = () => {
    scrollToTop();
    setCurrentStep('upload');
  };
  const postUpload = () => {
    scrollToTop();
    refreshAssignments();
    goToAssignmentList();
  };

  switch (currentStep) {
    case 'assignmentList':
      return (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-semibold">Assignments</h1>
            <button
              onClick={() => {
                handleCreateAssignmentClick();
                analyticsApi.logCreateAssignment(api.getCurrentUserId());
              }}
              className="owler-white-button"
            >
              Create Assignment
            </button>
          </div>
          <AssignmentList
            container={container}
            reuseCallback={() => {
              goToUploadDocuments();
              analyticsApi.logReuseAssignment(api.getCurrentUserId());
            }}
            editCallback={() => {
              goToCreateAssignment();
              analyticsApi.logDuplicateAndEditAssignment(
                api.getCurrentUserId()
              );
            }}
          />
        </>
      );
    case 'createAssignment':
      return (
        <AssignmentCreationPage
          container={container}
          goBack={() => {
            goToAssignmentList();
            analyticsApi.logAssignmentListPageOpen(api.getCurrentUserId());
          }}
          postUpload={postUpload}
        />
      );
    case 'upload':
      return (
        <ManageNewSubmissionsView
          onClickPrevious={() => {
            goToAssignmentList();
            analyticsApi.logAssignmentListPageOpen(api.getCurrentUserId());
          }}
          onCreateGradingJob={() => {
            postUpload();
            analyticsApi.logUploadStudentSubmissions(api.getCurrentUserId());
          }}
        />
      );
  }
};

export default AssignmentPage;
