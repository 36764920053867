import { useState, useEffect } from 'react';

import { useApi } from '../contexts/ApiProvider';
import { Profile } from '../models/user';
import { mergeObjects } from './index';
import { Container } from '../dependencyInjection/Container';

interface ProfileData {
  data: Profile;
  error: string;
  isLoading: boolean;
}

const defaultData: Profile = {
  userId: '',
  email: '',
  firstName: '',
  lastName: '',
  schoolName: '',
  department: '',
  typeOfSchool: '',
  city: '',
  state: '',
  zip: '',
  numberOfClasses: 0,
  numberOfStudents: 0,
  numberOfAssignments: 0,
};

const useProfileData = (container: Container): ProfileData => {
  const [data, setData] = useState<Profile>(defaultData);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { api } = useApi();

  if (!container) {
    return { data: defaultData, error: 'An unknown error', isLoading: true };
  }

  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoading(true);

      try {
        const currentUserData = await api.getCurrentUserData();
        const assignments = await container.assignmentsProvider.get();

        if (!currentUserData || !currentUserData.email) {
          const { email } = await api.getCurrentUser();
          setData((previousData) => ({ ...previousData, email }));
          throw new Error('No user data available.');
        }

        const profile: Profile = {
          ...mergeObjects(currentUserData, defaultData),
          numberOfAssignments: assignments.length,
        };

        setData(profile);
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : 'An unknown error occurred.';
        setError(errorMessage);
        console.error('Error profile data:', errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [api]);

  return { data, error, isLoading };
};

export default useProfileData;
