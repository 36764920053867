import React, { useState, SyntheticEvent } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

import TabPanel from '../../components/common/TabPanel';
import Loading from '../../components/common/Loading';
import NotAllowed from '../../components/common/NotAllowed';
import Assignments from './Assignments';
import { useOrganizationData } from '../../utils/useOrganizationData';
import { orgTabLabels } from '../../constants';

const Organization: React.FC = () => {
  const [value, setValue] = useState(0);
  const { data: orgData, isLoading } = useOrganizationData();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!orgData) {
    return <NotAllowed />;
  }

  const tablPanelItems = [<Assignments orgData={orgData} />];

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        {orgTabLabels.map((label, index) => (
          <Tab key={`Tab-${index}`} value={index} label={label} />
        ))}
      </Tabs>

      {tablPanelItems.map((item, index) => (
        <TabPanel key={`TabPanel-${index}`} value={value} index={index}>
          {item}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Organization;
