export const departmentOptions = [
  'ELA',
  'ESL',
  'ELL',
  'English',
  'Social Studies',
  'Humanities',
];
export const typeOfSchoolOptions = [
  'Public',
  'Charter',
  'Private',
  'Special Education',
  'Boarding',
  'International',
  'Home',
  'Virtual',
];
export const stateAbbreviations: string[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
