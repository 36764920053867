import React from 'react';
import { Box } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  gridClasses,
} from '@mui/x-data-grid-pro';

import AssignmentDetail from './AssignmentDetail';
import CustomToolbar from '../../common/CustomDataGridToolbar';
import CustomNoRowsOverlay from '../../common/CustomDataGridNoRowsOverlay';
import { getFormattedDateTime } from '../../../utils';
import { AssignmentRow, AssignmentRowDetail } from '../../../types';

interface AssignmentDataGridProps {
  rows: AssignmentRow[];
  rawData: AssignmentRowDetail[];
}

const columns: GridColDef[] = [
  { field: 'teacherEmail', headerName: 'Teacher Email', width: 200 },
  { field: 'teacherName', headerName: 'Teacher Name', width: 150 },
  { field: 'course', headerName: 'Course', width: 200 },
  { field: 'assignmentName', headerName: 'Assignment', width: 500 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'submissionCount', headerName: 'Submission', width: 150 },
  { field: 'evaluationTime', headerName: 'Evaluation Time', width: 150 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
    renderCell: (params: GridRenderCellParams<Date>) => (
      <span>{getFormattedDateTime(params.value)}</span>
    ),
  },
];

const AssignmentDataGrid: React.FC<AssignmentDataGridProps> = ({
  rows,
  rawData,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [
              { field: 'type', sort: 'desc' },
              { field: 'createdAt', sort: 'desc' },
            ],
          },
        }}
        pagination
        pageSizeOptions={[10, 20, 50]}
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getDetailPanelContent={({ row }) => (
          <AssignmentDetail
            data={rawData.find((item) => item.data.assignment_id === row.id)}
          />
        )}
        getDetailPanelHeight={() => 'auto'}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </Box>
  );
};

export default AssignmentDataGrid;
