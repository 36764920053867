import React from 'react';
import { Assignment } from '../../../types';
import { AssignmentReference } from './AssignmentReference';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

type AssignmentEditIconProps = {
  assignment: Assignment;
  isSelected: boolean;
  handleEditButtonClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  setSelectedAssignment: (reference: AssignmentReference) => void;
};

const AssignmentEditIcon: React.FC<AssignmentEditIconProps> = ({
  assignment,
  isSelected,
  handleEditButtonClick,
  setSelectedAssignment,
}) => {
  const handleEditClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (isSelected) {
      handleEditButtonClick(e);
    } else {
      const reference: AssignmentReference = {
        assignment_id: assignment.assignment_id,
        assignment_metadata_info: assignment.assignment_metadata_info,
        assignment_name: assignment.assignment_name,
        created_at: assignment.created_at,
        grading_job_id: assignment.grading_job_id,
        conversion_job_id: assignment.conversion_job_id,
      };
      setSelectedAssignment(reference);
    }
  };

  return (
    <button
      onClick={handleEditClick}
      className="w-5 text-gray-600 hover:text-gray-800 rounded-md"
    >
      <PencilSquareIcon />
    </button>
  );
};

export default AssignmentEditIcon;
