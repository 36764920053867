import { DescriptorRange } from '../types';

export const gradeDescriptorMapping: DescriptorRange[] = [
  // scale is translated to be from 50-100%, so for this mapping, 0 = 50% and 50 = 100%
  { range: [0, 12.5], descriptor: 'Does Not Meet Expectations' },
  { range: [12.5, 25], descriptor: 'Partially Meets Expectations' },
  { range: [25, 37.5], descriptor: 'Mostly Meets Expectations' },
  // go up to 51 to include 50% as a possible grade
  { range: [37.5, 51], descriptor: 'Meets Expectations' },
];
