import React, { useMemo } from 'react';
import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';

import { formatNumber } from '../../../utils';
import { Rubric } from '../../../types';
import { SubmissionDetailTableRow } from '../../../types';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Rubric Name',
    width: 150,
  },
  { field: 'description', headerName: 'Rubric Description', width: 500 },
  {
    field: 'points_possible',
    headerName: 'Points Possible',
    width: 150,
  },
];

interface SubmissionDetailTableProps {
  rubrics?: Rubric[];
}

const SubmissionDetailTable: React.FC<SubmissionDetailTableProps> = ({
  rubrics,
}) => {
  const rows = useMemo(
    () =>
      (rubrics || []).map(
        ({ id, name, description, points_possible }) =>
          ({
            id,
            name,
            description,
            points_possible: formatNumber(Number(points_possible)),
          }) as SubmissionDetailTableRow
      ),
    [rubrics]
  );

  return (
    <div className="w-100">
      <DataGridPro
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        getRowHeight={() => 'auto'}
        pagination
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '& .MuiDataGrid-detailPanel': {
            overflow: 'visible',
          },
        }}
      />
    </div>
  );
};

export default SubmissionDetailTable;
