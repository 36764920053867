import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

interface PasswordInputProps {
  fieldRegister: UseFormRegisterReturn;
  fieldError?: FieldError;
  label: string;
  id: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  fieldRegister,
  fieldError,
  label,
  id,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}
      variant="outlined"
      error={!!fieldError}
    >
      <InputLabel htmlFor={`outlined-${id}`}>{label}</InputLabel>
      <OutlinedInput
        id={`outlined-${id}`}
        {...fieldRegister}
        type={showPassword ? 'text' : 'password'}
        inputProps={{
          className: 'focus:ring-0',
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      {fieldError && (
        <FormHelperText error>{fieldError.message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default PasswordInput;
