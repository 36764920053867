import { GrammarMistake, RubricGrading } from '../../types';
import { highlightColors } from '../../constants';
import FuzzySet from 'fuzzyset';

export type SegmentType = 'rubric' | 'grammar_mistake';
export interface Highlight {
  type: SegmentType;
  index: number;
  length: number;
  color: string;
  rubricName?: string;
  correction?: string;
  explanation?: string;
}

export interface SentenceSegment {
  type: SegmentType;
  text: string;
  color: string;
  rubricName?: string;
  correction?: string;
  explanation?: string;
}

export function splitByPunctuation(input: string): string[] {
  const trimmedInput = input.replace(/\s+/g, '');
  return trimmedInput.split(/[.!?]/).filter((word) => word !== '');
}

export function createIndexMapping(originalText: string): Map<number, number> {
  const indexMap = new Map<number, number>();
  let strippedIndex = 0;

  for (let i = 0; i < originalText.length; i++) {
    if (!/\s+|[.!?]/.test(originalText[i])) {
      indexMap.set(strippedIndex, i);
      strippedIndex++;
    }
  }

  return indexMap;
}

export function generateHighlights(
  studentSubmission: string,
  rubricGrading: RubricGrading,
  grammarMistakes: GrammarMistake[]
): Highlight[] {
  const splitText = splitByPunctuation(studentSubmission);
  const strippedText = splitText.join('');
  const indexMap = createIndexMapping(studentSubmission);
  const fuzzy = FuzzySet(splitText);
  const matchThreshold = 0.5;

  const highlights: Highlight[] = [];
  const textGroups: SentenceSegment[] = [];

  for (const rubricResult of rubricGrading) {
    const rubricName = Object.keys(rubricResult)[0];
    const highlightedTextArr = rubricResult[rubricName].highlighted_text;
    const color =
      highlightColors[
        rubricGrading.indexOf(rubricResult) % highlightColors.length
      ];
    textGroups.push(
      ...(highlightedTextArr || []).map((text) => ({
        type: 'rubric' as SegmentType,
        text,
        color,
        rubricName,
      }))
    );
  }

  for (const grammarMistake of grammarMistakes) {
    textGroups.push({
      type: 'grammar_mistake' as SegmentType,
      text: grammarMistake.mistake,
      correction: grammarMistake.correction,
      explanation: grammarMistake.explanation,
      color: 'underline decoration-wavy decoration-red-500',
    });
  }

  for (const textGroup of textGroups) {
    const { type, text, color, rubricName, correction, explanation } =
      textGroup;
    const splitHighlighted = splitByPunctuation(text);
    let startSearchIndex = 0;

    for (const sentence of splitHighlighted) {
      let result = fuzzy.get(sentence);

      while (result) {
        const [score, matchedString] = result[0];
        if (score > matchThreshold) {
          const strippedIndex = strippedText.indexOf(
            matchedString,
            startSearchIndex
          );
          const originalIndex = indexMap.get(strippedIndex);

          if (originalIndex !== undefined) {
            let originalLength = 0;

            for (let i = originalIndex; i < studentSubmission.length; i++) {
              if (/[.!?]/.test(studentSubmission[i])) {
                break;
              }
              originalLength++;
            }

            const highlight = {
              index: originalIndex,
              length: originalLength,
              color,
              type,
            };

            highlights.push({
              ...highlight,
              ...(type === 'rubric'
                ? { rubricName }
                : { correction, explanation }),
            });

            startSearchIndex = strippedIndex + matchedString.length;
          } else {
            break;
          }
        } else {
          break;
        }

        // Look for the next occurrence in the stripped text
        result = fuzzy.get(sentence);
      }
    }
  }

  return highlights;
}

export function parseGradeWithPercentSign(grade: string) {
  let gradeStr = String(grade);
  if (gradeStr.endsWith('%')) {
    gradeStr = gradeStr.slice(0, -1);
  }
  return parseFloat(gradeStr);
}
