import { useEffect, useState } from 'react';
import { Assignment } from '../../../types';
import { AssignmentProvider } from '../AssignmentList/AssignmentProvider';

function useAssignmentById(
  assignmentId: string,
  assignmentProvider: AssignmentProvider
): Assignment | undefined {
  const [assignment, setAssignment] = useState<Assignment | undefined>(
    undefined
  );

  useEffect(() => {
    assignmentProvider.getById(assignmentId).then((assignment) => {
      setAssignment(assignment);
    });
  }, [assignmentId, assignmentProvider]);

  return assignment;
}

export { useAssignmentById };
