import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

interface AddBulletPointButtonProps {
  onAdd: () => void;
}

const AddBulletPointButton: React.FC<AddBulletPointButtonProps> = ({
  onAdd,
}) => {
  return (
    <button
      onClick={onAdd}
      className="mt-2 w-8 text-gray-500 hover:text-green-500 rounded"
    >
      <PlusCircleIcon />
    </button>
  );
};

export default AddBulletPointButton;
