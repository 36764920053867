import { z } from 'zod';

export const basicInfoSettingSchema = z.object({
  firstName: z.string().nonempty('First name is required'),
  lastName: z.string().nonempty('Last name is required'),
  numberOfStudents: z
    .number({ invalid_type_error: 'Number of Students must be a number' })
    .min(0, 'Number of Students is required'),
  numberOfClasses: z
    .number({ invalid_type_error: 'Number of Classes must be a number' })
    .min(0, 'Number of Classes is required'),
});

export type BasicInfoSettingForm = z.infer<typeof basicInfoSettingSchema>;
