import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

interface DeleteBulletPointButtonProps {
  onDelete: () => void;
}

const DeleteBulletPointButton: React.FC<DeleteBulletPointButtonProps> = ({
  onDelete,
}) => {
  return (
    <button
      onClick={onDelete}
      className="ml-2 w-8 h-8 text-gray-500 hover:text-red-500 flex items-center justify-center" // Set a fixed size
    >
      <XCircleIcon className="w-8 h-8" />
    </button>
  );
};

export default DeleteBulletPointButton;
