import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';

import { useCurrentAssignment } from '../../contexts/CurrentAssignmentContext';
import TextInput from '../common/TextInput';
import { assignmentInputs, ASSIGNMENT_NAME_INPUT_ID } from '../../constants';
import {
  assignmentSchema,
  AssignmentForm as AssignmentSettingForm,
} from '../../validation';

interface AssignmentFormProps {
  goBack?: () => void;
  submit: () => void;
}

interface Input {
  id: keyof AssignmentSettingForm;
  label: string;
  placeholder: string;
  type: string;
  defaultValue: string;
}

const AssignmentForm: React.FC<AssignmentFormProps> = ({ goBack, submit }) => {
  const {
    currentAssignment,
    setCurrentAssignment,
    setAssignmentMetadataContent,
  } = useCurrentAssignment();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<AssignmentSettingForm>({
    resolver: zodResolver(assignmentSchema),
  });

  const inputs: Input[] = useMemo(
    () => [
      ...assignmentInputs.map(({ id, label, placeholder, type }) => {
        const defaultValue =
          id === ASSIGNMENT_NAME_INPUT_ID
            ? currentAssignment.assignment_name
            : currentAssignment.metadata_content[id];
        return {
          id: id as keyof AssignmentSettingForm,
          label,
          placeholder,
          type,
          defaultValue: defaultValue,
        };
      }),
    ],
    [currentAssignment]
  );

  useEffect(() => {
    reset(
      inputs.reduce((acc, { id, defaultValue }) => {
        acc[id as keyof AssignmentSettingForm] = defaultValue;
        return acc;
      }, {} as Partial<AssignmentSettingForm>)
    );
  }, [inputs, reset]);

  const onSubmit = async (data: AssignmentSettingForm) => {
    setCurrentAssignment((prev) => ({
      ...prev,
      assignment_name: data.assignment_name,
    }));
    setAssignmentMetadataContent({
      ...currentAssignment.metadata_content,
      ...assignmentInputs
        .filter((item) => item.id !== ASSIGNMENT_NAME_INPUT_ID)
        .reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: data[item.id as keyof AssignmentSettingForm],
          }),
          {}
        ),
    });
    submit();
  };

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    }
  };

  return (
    <div className="mx-auto max-w-5xl p-6 space-y-6 bg-white rounded-md">
      <h1 className="text-xl font-semibold">Create Assignment</h1>
      <p>
        First let's add some background information about the assignment. This
        information is used to tailor the grading output to your specific
        student body.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          {inputs.map(({ id, label, placeholder, type, defaultValue }) => (
            <div key={`${id}-${defaultValue}`}>
              <TextInput
                id={id}
                label={label}
                placeholder={placeholder}
                multiline={type === 'textArea'}
                rows={4}
                defaultValue={defaultValue}
                fieldRegister={register(id)}
                fieldError={errors[id]}
                isSubmitted={isSubmitted}
                autoFocus={id === ASSIGNMENT_NAME_INPUT_ID}
              />
            </div>
          ))}
        </div>

        <div className={`flex ${goBack ? 'justify-between' : 'justify-end'}`}>
          {goBack && (
            <button
              onClick={handleGoBack}
              className="owler-indigo-secondary-button"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2" />
              Previous
            </button>
          )}
          <button type="submit" className="owler-indigo-button">
            Create Assignment
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssignmentForm;
