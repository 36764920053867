import React from 'react';

import OrganizationAssignmentDetails from './OrganizationAssignmentDetails';
import AssignmentDataGrid from './AssignmentDataGrid';
import Loading from '../../common/Loading';
import { AssignmentRow, AssignmentRowDetail } from '../../../types';

interface OrganizationAssignmentsProps {
  rows?: AssignmentRow[];
  rawData?: AssignmentRowDetail[];
  apiUserId?: string;
  isLoading: boolean;
}

const OrganizationAssignments: React.FC<OrganizationAssignmentsProps> = ({
  rows,
  rawData,
  apiUserId = '',
  isLoading,
}) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!rows || !rawData) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      <OrganizationAssignmentDetails userId={apiUserId} />
      <AssignmentDataGrid rows={rows} rawData={rawData} />
    </div>
  );
};

export default OrganizationAssignments;
