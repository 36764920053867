import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Assignment, AssignmentMetadataContent, Rubric } from '../types';
import { AssignmentMetadataInfo } from '../components/AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';
import { createRubric } from '../components/assignments/createRubric';

interface CurrentAssignmentContextType {
  currentAssignment: Assignment;
  assignmentMetadataContent: AssignmentMetadataContent;
  originalAssignmentMetadataContent: AssignmentMetadataContent;
  assignmentMetadataInfo: AssignmentMetadataInfo;
  isMetadataUpdated: boolean;
  setCurrentAssignment: React.Dispatch<React.SetStateAction<Assignment>>;
  setAssignmentMetadataContent: (content: AssignmentMetadataContent) => void;
  setAssignmentMetadataInfo: (info: AssignmentMetadataInfo) => void;
  setOriginalAssignmentMetadataContent: React.Dispatch<
    React.SetStateAction<AssignmentMetadataContent>
  >;
  setRubrics: (rubrics: Rubric[]) => void;
  addRubric: () => void;
  removeRubric: (rubric: Rubric) => void;
  updateRubric: (rubric: Rubric) => void;
  resetCurrentAssignment: () => void;
}

const CurrentAssignmentContext = createContext<
  CurrentAssignmentContextType | undefined
>(undefined);

interface CurrentAssignmentProviderProps {
  children: React.ReactNode;
}

const defaultMetadataContent: AssignmentMetadataContent = {
  department: '',
  teacher_type: '',
  school_type: '',
  course: '',
  question_background: '',
  question: '',
  rubric: [createRubric()],
  version: 'v1',
};

const defaultAssignmentMetadataInfo: AssignmentMetadataInfo = {
  assignment_metadata_path: '',
  assignment_metadata_id: '',
  course: '',
  created_at: new Date(),
  question_truncated: '',
  version: 'v1',
};

const defaultAssignmentData: Assignment = {
  assignment_id: '',
  assignment_metadata_info: defaultAssignmentMetadataInfo,
  assignment_name: '',
  created_at: new Date(),
  grading_job_id: '',
  conversion_job_id: '',
  metadata_content: defaultMetadataContent,
};

export const CurrentAssignmentProvider: React.FC<
  CurrentAssignmentProviderProps
> = ({ children }) => {
  const [currentAssignment, setCurrentAssignment] = useState<Assignment>(
    defaultAssignmentData
  );
  const [
    originalAssignmentMetadataContent,
    setOriginalAssignmentMetadataContent,
  ] = useState<AssignmentMetadataContent>(defaultMetadataContent);

  const assignmentMetadataContent = useMemo(
    () => currentAssignment.metadata_content,
    [currentAssignment.metadata_content]
  );
  const assignmentMetadataInfo = useMemo(
    () => currentAssignment.assignment_metadata_info,
    [currentAssignment.assignment_metadata_info]
  );
  const isMetadataUpdated = useMemo(() => {
    const metadata1 = currentAssignment.metadata_content;
    const metadata2 = originalAssignmentMetadataContent;

    const simpleFields: (keyof AssignmentMetadataContent)[] = [
      'department',
      'teacher_type',
      'school_type',
      'course',
      'question_background',
      'question',
    ];

    const simpleFieldsMatch = simpleFields.every(
      (field) => metadata1[field] === metadata2[field]
    );

    const rubricMatch =
      metadata1.rubric.length === metadata2.rubric.length &&
      metadata1.rubric.every((rubric, index) => {
        const otherRubric = metadata2.rubric[index];
        return (
          rubric.name === otherRubric.name &&
          rubric.description === otherRubric.description &&
          rubric.points_possible === otherRubric.points_possible
        );
      });

    return !(simpleFieldsMatch && rubricMatch);
  }, [currentAssignment, originalAssignmentMetadataContent]);

  const setAssignmentMetadataContent = useCallback(
    (content: AssignmentMetadataContent) =>
      setCurrentAssignment((prev) => ({ ...prev, metadata_content: content })),
    [setCurrentAssignment]
  );

  const setAssignmentMetadataInfo = useCallback(
    (info: AssignmentMetadataInfo) =>
      setCurrentAssignment((prev) => ({
        ...prev,
        assignment_metadata_info: info,
      })),
    [setCurrentAssignment]
  );

  const setRubrics = useCallback((rubrics: Rubric[]) => {
    setCurrentAssignment((prev) => ({
      ...prev,
      metadata_content: {
        ...prev.metadata_content,
        rubric: rubrics,
      },
    }));
  }, []);

  const addRubric = useCallback(() => {
    setCurrentAssignment((prev) => ({
      ...prev,
      metadata_content: {
        ...prev.metadata_content,
        rubric: [...prev.metadata_content.rubric, createRubric()],
      },
    }));
  }, []);

  const removeRubric = useCallback((rubric: Rubric) => {
    setCurrentAssignment((prev) => ({
      ...prev,
      metadata_content: {
        ...prev.metadata_content,
        rubric: prev.metadata_content.rubric.filter((r) => r.id !== rubric.id),
      },
    }));
  }, []);

  const updateRubric = useCallback((rubric: Rubric) => {
    setCurrentAssignment((prev) => ({
      ...prev,
      metadata_content: {
        ...prev.metadata_content,
        rubric: prev.metadata_content.rubric.map((r) =>
          r.id === rubric.id ? rubric : r
        ),
      },
    }));
  }, []);

  const resetCurrentAssignment = useCallback(() => {
    setAssignmentMetadataContent(defaultMetadataContent);
    setOriginalAssignmentMetadataContent(defaultMetadataContent);
    setAssignmentMetadataInfo({
      assignment_metadata_id: '',
      assignment_metadata_path: '',
    });
  }, []);

  return (
    <CurrentAssignmentContext.Provider
      value={{
        currentAssignment,
        assignmentMetadataContent,
        originalAssignmentMetadataContent,
        assignmentMetadataInfo,
        isMetadataUpdated,
        setCurrentAssignment,
        setOriginalAssignmentMetadataContent,
        setAssignmentMetadataContent,
        setAssignmentMetadataInfo,
        setRubrics,
        addRubric,
        removeRubric,
        updateRubric,
        resetCurrentAssignment,
      }}
    >
      {children}
    </CurrentAssignmentContext.Provider>
  );
};

export const useCurrentAssignment = () => {
  const context = useContext(CurrentAssignmentContext);
  if (!context) {
    throw new Error(
      'useCurrentAssignment must be used within a CurrentAssignmentProvider'
    );
  }
  return context;
};
