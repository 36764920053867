import React from 'react';
import { Assignment, AssignmentMetadataContent } from '../../../types';
import { ArrowRightIcon, PencilIcon } from '@heroicons/react/24/solid';

import { useCurrentAssignment } from '../../../contexts/CurrentAssignmentContext';

type AssignmentSelectionButtonsProps = {
  assignment?: Assignment;
  assignmentMetadataContent?: AssignmentMetadataContent;
  editCallback?: () => void;
  reuseCallback: () => void;
  editDescription?: string;
  reuseDescription?: string;
  isMetadata?: boolean;
};

const AssignmentSelectionButtons: React.FC<AssignmentSelectionButtonsProps> = ({
  assignment,
  assignmentMetadataContent,
  editCallback,
  reuseCallback,
  editDescription = 'Duplicate & Edit Assignment',
  reuseDescription = 'Reuse Assignment',
  isMetadata = false,
}) => {
  const {
    setCurrentAssignment,
    setAssignmentMetadataContent,
    setOriginalAssignmentMetadataContent,
    resetCurrentAssignment,
  } = useCurrentAssignment();

  const handleClick = () => {
    if (isMetadata && assignmentMetadataContent) {
      resetCurrentAssignment();
      setAssignmentMetadataContent(assignmentMetadataContent);
    }
    if (assignment) {
      setOriginalAssignmentMetadataContent(assignment?.metadata_content);
      if (!isMetadata) {
        setCurrentAssignment(assignment);
      }
    }
  };

  return (
    <div className="flex mt-3 justify-end">
      {editCallback && (
        <button
          onClick={(event) => {
            // Prevents the event from reaching the parent, which would collapse the li and reset the assignmentMetadataContent
            event.stopPropagation();
            handleClick();
            editCallback();
          }}
          className="owler-white-button flex mx-2 items-center"
        >
          <span className="flex items-center">
            {editDescription}
            <PencilIcon className="w-4 h-4 ml-2 inline-block" />
          </span>
        </button>
      )}
      <button
        onClick={(event) => {
          // Prevents the event from reaching the parent, which would collapse the li and reset the assignmentMetadataContent
          event.stopPropagation();
          handleClick();
          reuseCallback();
        }}
        className="owler-white-button flex ml-2 items-center"
      >
        <span className="flex items-center">
          {reuseDescription}
          <ArrowRightIcon className="w-4 h-4 ml-2 inline-block" />
        </span>
      </button>
    </div>
  );
};

export default AssignmentSelectionButtons;
