import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { useAssignmentsContext } from '../../../contexts/AssignmentsContextProvider';

interface AssignmentRenameDialogProps {
  assignmentId: string;
  assignmentName: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateLocalAssignment: (newName: string) => void;
}

const AssignmentRenameDialog: React.FC<AssignmentRenameDialogProps> = ({
  assignmentId,
  assignmentName,
  open,
  setOpen,
  updateLocalAssignment,
}) => {
  const { updateAssignmentName } = useAssignmentsContext();
  const [newAssignmentName, setNewAssignmentName] = useState(assignmentName);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    updateLocalAssignment(newAssignmentName);
    handleClose();

    await updateAssignmentName(assignmentId, newAssignmentName);
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    const ENTER = 13;
    if (event.keyCode === ENTER) {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
    >
      <DialogTitle>Rename assignment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To rename the assignment, please enter the new assignment name here.
          We will update the name.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="newName"
          label="New Assignment Name"
          type="text"
          value={newAssignmentName}
          onChange={(e) => setNewAssignmentName(e.target.value)}
          inputProps={{
            className: 'focus:ring-0',
          }}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Rename</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignmentRenameDialog;
