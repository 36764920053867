import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { sidebarItems } from '../../../constants';

interface SettingSidebarProps {
  onItemClick: (key: string) => void;
}

const SettingSidebar: React.FC<SettingSidebarProps> = ({ onItemClick }) => {
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200 mt-4 w-80">
      <div className="px-4 py-5 sm:p-6">
        <List>
          {sidebarItems.map(({ key, label, icon: Icon }) => (
            <ListItemButton key={key} onClick={() => onItemClick(key)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          ))}
        </List>
      </div>
    </div>
  );
};

export default SettingSidebar;
