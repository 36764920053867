import * as React from 'react';

interface ApiStudentResponseProps {
  questions: string | string[];
  answers: string | string[];
  explanation: string;
}

export default function ApiStudentResponse({
  questions,
  answers,
  explanation,
}: ApiStudentResponseProps) {
  const questionList = typeof questions === 'string' ? [questions] : questions;
  const answerList = typeof answers === 'string' ? [answers] : answers;

  return (
    <React.Fragment>
      {questionList.map((question, index) => (
        <div key={index} className="flex flex-col gap-2 mb-4">
          <div>
            <p className="font-bold">Question #{index + 1}: </p>
            <p>{question}</p>
          </div>
          <div>
            <p className="font-bold">Answer: </p>
            <p>{answerList[index] || '-'}</p>
          </div>
        </div>
      ))}
      <div>
        <p className="font-bold">Explanation: </p>
        <p>{explanation}</p>
      </div>
    </React.Fragment>
  );
}
