import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAssignmentStatus } from '../../../utils/useAssignmentStatus';
import { AssignmentReference } from './AssignmentReference';
import AssignmentStatusView from './AssignmentStatusView';
import { useAssignment } from '../hooks/useAssignment';
import { Container } from '../../../dependencyInjection/Container';
import { useGradingStatus } from '../hooks/useGradingStatus';
import { GradingStatuses } from './GradingStatuses';
import ListElementHeader from '../../utils/ListElementHeader';
import ListElementSubHeader from '../../utils/ListElementSubHeader';
import AssignmentSettings from './AssignmentSettings';
import AssignmentNameEditor from './AssignmentNameEditor';
import AssignmentDetails from './AssignmentDetails';
import AssignmentEditIcon from './AssignmentEditIcon';
import AssignmentRenameDialog from './AssignmentRenameDialog';
import { ASSIGNMENT_LOADING_STATUS } from '../../../constants';

type AssignmentRowViewProps = {
  assignmentReference: AssignmentReference;
  isSelected: boolean;
  reuseCallback: () => void;
  editCallback: () => void;
  container: Container;
  setSelectedAssignment: (reference: AssignmentReference) => void;
};

const AssignmentRowView: React.FC<AssignmentRowViewProps> = ({
  assignmentReference,
  isSelected,
  reuseCallback,
  editCallback,
  container,
  setSelectedAssignment,
}) => {
  const { assignment, refetch } = useAssignment(
    assignmentReference,
    container.assignmentProvider
  );

  const gradingStatus = useGradingStatus(
    container.assignmentStatusProvider,
    assignment
  );

  const isCompleted = gradingStatus?.jobStatus === GradingStatuses.Completed;

  const { data: assignmentStatus } = useAssignmentStatus(
    isCompleted ? (assignmentReference.assignment_id ?? '') : ''
  );

  const {
    status = ASSIGNMENT_LOADING_STATUS,
    totalCount = 0,
    failedCount = 0,
  } = assignmentStatus || {};

  const showViewAssignmentButton =
    status !== ASSIGNMENT_LOADING_STATUS && isCompleted;

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const [editingAssignmentId, setEditingAssignmentId] = useState<string | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);
  const [localAssignment, setLocalAssignment] = useState(assignment);

  useEffect(() => {
    if (assignment) {
      setLocalAssignment(assignment);
    }
  }, [refetch, assignment]);

  const handleEditCallback = () => {
    setSelectedAssignment(assignmentReference);
    setEditingAssignmentId(assignmentReference.assignment_id);
    setIsEditing(true);
  };

  const handleRename = () => {
    setOpenDialog(true);
  };

  const updateLocalAssignment = (newName: string) => {
    setLocalAssignment((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        assignment_name: newName,
      };
    });
  };

  return (
    <div className="max-w-full">
      <div className="flex items-center gap-x-6">
        <div className="flex-1 min-w-0">
          {localAssignment && (
            <>
              <AssignmentRenameDialog
                open={openDialog}
                setOpen={setOpenDialog}
                updateLocalAssignment={updateLocalAssignment}
                assignmentId={localAssignment.assignment_id}
                assignmentName={localAssignment.assignment_name}
              />
              <div className="flex items-center">
                {isEditing &&
                isSelected &&
                editingAssignmentId === assignmentReference.assignment_id ? (
                  <AssignmentNameEditor
                    assignment={localAssignment}
                    setIsEditing={setIsEditing}
                    updateLocalAssignment={updateLocalAssignment}
                  />
                ) : (
                  <>
                    <div className="flex items-center">
                      <ListElementHeader
                        text={
                          localAssignment.assignment_name === undefined
                            ? localAssignment.metadata_content.question
                            : localAssignment.assignment_name
                        }
                      />
                      {isSelected && (
                        <AssignmentEditIcon
                          assignment={localAssignment}
                          isSelected={isSelected}
                          handleEditButtonClick={handleEditCallback}
                          setSelectedAssignment={setSelectedAssignment}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              <ListElementSubHeader
                date={new Date(localAssignment.created_at).toDateString()}
              />
            </>
          )}
        </div>
        <div className="flex items-center gap-x-6 justify-end">
          <div className="flex items-center gap-x-4">
            {localAssignment && (
              <div className="items-top">
                <AssignmentStatusView
                  gradingStatus={gradingStatus}
                  submissionStatus={status}
                  totalCount={totalCount}
                  failedCount={failedCount}
                />
              </div>
            )}
            {showViewAssignmentButton && localAssignment && (
              <Link
                to={`/assignments/${localAssignment?.assignment_id}`}
                className="owler-white-button"
                onClick={handleButtonClick}
              >
                View assignment
                <span className="sr-only">
                  , {localAssignment.metadata_content.question}
                </span>
              </Link>
            )}
            {localAssignment && (
              <AssignmentSettings
                assignment={localAssignment}
                renameCallback={handleRename}
                editCallback={editCallback}
                reuseCallback={reuseCallback}
              />
            )}
          </div>
        </div>
      </div>
      {isSelected && localAssignment && (
        <AssignmentDetails
          container={container}
          assignment={localAssignment}
          editCallback={editCallback}
          reuseCallback={reuseCallback}
        />
      )}
    </div>
  );
};

export default AssignmentRowView;
