import React, { FC } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import { Container } from '../../dependencyInjection/Container';
import ProfileComposite from '../../components/profile/ProfileComposite';

interface ProfileViewProps {
  container: Container;
}

const ProfileView: FC<ProfileViewProps> = ({ container }) => {
  return (
    <Dashboard
      currentNavigation="Profile"
      children={<ProfileComposite container={container} />}
    />
  );
};

export default ProfileView;
