import { AssignmentMetadatSection } from '../types';
export const orgTabLabels = ['Assignments'];

export const apiAssignmentMetadataSections: AssignmentMetadatSection[] = [
  {
    key: 'row-details',
    items: [
      {
        key: 'department',
        label: 'Department',
      },
      {
        key: 'course',
        label: 'Course',
      },
      {
        key: 'teacher_type',
        label: 'Teacher Type',
      },
      {
        key: 'grade_level',
        label: 'Grade Level',
      },
    ],
  },
];

export const platformAssignmentMetadataSections: AssignmentMetadatSection[] = [
  ...apiAssignmentMetadataSections,
  {
    key: 'row-question',
    items: [
      {
        key: 'question',
        label: 'Question',
      },
    ],
  },
  {
    key: 'question-background',
    items: [
      {
        key: 'question_background',
        label: 'Question Background',
      },
    ],
  },
];

export const PLATFORM_ROW_DETAIL_TYPE = 'platform';
export const API_ROW_DETAIL_TYPE = 'api';
