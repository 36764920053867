import React, { FC, createContext, useState, useContext, useMemo } from 'react';
import {
  mockAssignmentDetails,
  mockAssignmentQuestion,
  mockAssignmentMetadataContent,
  mockBreadcrumbs,
  mockAssignmentList,
  mockGradingResult,
  mockJobId,
  mockStudentSubmission,
} from '../mock';
import { MockContextType, MockProviderProps } from '../types';

const MockContext = createContext<MockContextType | undefined>(undefined);

const mockContentProvider = {
  getMockAssignmentDetails: () => mockAssignmentDetails,
  getMockAssignmentQuestion: () => mockAssignmentQuestion,
  getMockAssignmentMetadataContent: () => mockAssignmentMetadataContent,
  getMockBreadcrumbs: () => mockBreadcrumbs,
  getMockAssignmentList: () => mockAssignmentList,
  getMockGradingResult: () => mockGradingResult,
  getMockJobId: () => mockJobId,
  getMockStudentSubmission: () => mockStudentSubmission,
};

const MockProvider: FC<MockProviderProps> = ({ children }) => {
  const [useMocks, setUseMocks] = useState(false);

  const toggleMock = () => {
    setUseMocks((prevState) => !prevState);
  };

  const enableMock = () => {
    setUseMocks(true);
  };

  const disableMock = () => {
    setUseMocks(false);
  };

  const contextValue: MockContextType = useMemo(
    () => ({
      useMocks,
      mockContentProvider,
      toggleMock,
      enableMock,
      disableMock,
    }),
    [useMocks]
  );

  return (
    <MockContext.Provider value={contextValue}>{children}</MockContext.Provider>
  );
};

const useMock = (): MockContextType => {
  const context = useContext(MockContext);

  if (context === undefined) {
    throw new Error('useMock must be used within a MockProvider');
  }

  const { useMocks, mockContentProvider, toggleMock, enableMock, disableMock } =
    context;

  return {
    useMocks,
    mockContentProvider,
    toggleMock,
    enableMock,
    disableMock,
  };
};

export { MockProvider, useMock };
