import {
  Firestore,
  collection,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { AssignmentReference } from './AssignmentReference';
import { AssignmentMetadataInfo } from '../../AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';

export interface AssignmentsProvider {
  get(): Promise<AssignmentReference[]>;
}

export class FirebaseAssignmentsProvider implements AssignmentsProvider {
  constructor(
    private readonly _firestore: Firestore,
    private readonly _userID: string
  ) {}

  async get(): Promise<AssignmentReference[]> {
    const assignmentsColRef = collection(
      this._firestore,
      'users',
      this._userID,
      'assignments'
    );
    const q = query(assignmentsColRef, orderBy('created_at', 'desc'));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => {
      const firebaseAssignment = doc.data();

      const assignmentReference: AssignmentReference = {
        assignment_id: firebaseAssignment.assignment_id,
        // TODO: store this as this object in firebase
        assignment_metadata_info: {
          assignment_metadata_id: firebaseAssignment.assignment_metadata_id,
          assignment_metadata_path: firebaseAssignment.assignment_metadata_path,
        } as AssignmentMetadataInfo,
        assignment_name: firebaseAssignment.assignment_name,
        created_at: firebaseAssignment.created_at.toDate(),
        grading_job_id: firebaseAssignment.grading_job_id,
        conversion_job_id: firebaseAssignment.conversion_job_id,
        isArchived: !!firebaseAssignment.isArchived,
      };

      return assignmentReference;
    });
  }
}
