import { z } from 'zod';

export const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .nonempty('Password is required')
      .min(8, 'Password must be at least 8 characters long.'),
    confirmPassword: z
      .string()
      .nonempty('Confirm password is required')
      .min(8, 'Confirm password must be at least 8 characters long.'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match.',
    path: ['confirmPassword'],
  });

export type ResetPasswordForm = z.infer<typeof resetPasswordSchema>;
