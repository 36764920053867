import React, { useEffect, useRef } from 'react';

import BasicInfoCard from '../../components/Settings/account/BasicInfoCard';
import SchoolInfoCard from '../../components/Settings/account/SchoolInfoCard';
import SettingSidebar from '../../components/Settings/account/SettingSidebar';
import Loading from '../../components/common/Loading';
import useProfileData from '../../utils/useProfileData';
import { BASIC_INFO_CARD_KEY, SCHOOL_INFO_CARD_KEY } from '../../constants';
import { Container } from '../../dependencyInjection/Container';

interface AccountSettingsProps {
  container: Container;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({ container }) => {
  const profileData = useProfileData(container);

  const basicInfoRef = useRef<HTMLDivElement>(null);
  const schoolInfoRef = useRef<HTMLDivElement>(null);

  const handleSidebarClick = (key: string) => {
    if (key === BASIC_INFO_CARD_KEY && basicInfoRef.current) {
      basicInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (key === SCHOOL_INFO_CARD_KEY && schoolInfoRef.current) {
      schoolInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex gap-4 w-full items-start">
      <SettingSidebar onItemClick={handleSidebarClick} />
      {profileData.isLoading ? (
        <Loading />
      ) : (
        <div className="flex-grow">
          <div ref={basicInfoRef}>
            <BasicInfoCard {...profileData} />
          </div>

          <div ref={schoolInfoRef}>
            <SchoolInfoCard {...profileData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
