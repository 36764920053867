import { AssignmentInput } from '../types';

export const ASSIGNMENT_NAME_INPUT_ID = 'assignment_name';

export const assignmentInputs: AssignmentInput[] = [
  {
    id: ASSIGNMENT_NAME_INPUT_ID,
    label: 'Assignment Name',
    placeholder: 'Assignment Name',
    type: 'text',
  },
  {
    id: 'department',
    label: 'Department',
    placeholder: 'Department',
    type: 'text',
  },
  {
    id: 'teacher_type',
    label: 'Teacher Type',
    placeholder: 'Teacher Type (e.g. Teacher, Professor, etc.)',
    type: 'text',
  },
  {
    id: 'school_type',
    label: 'School Type',
    placeholder: 'School Type (e.g. High School, College, etc.)',
    type: 'text',
  },
  {
    id: 'course',
    label: 'Course Title',
    placeholder: 'Course Title',
    type: 'text',
  },
  {
    id: 'grade_level',
    label: 'Grade Level',
    placeholder: 'Grade Level (e.g. 9th, 10th, etc.)',
    type: 'text',
  },
  {
    id: 'question_background',
    label: 'Essay Background',
    placeholder:
      'Please include relevant background information for this essay prompt (e.g., relevant books, type of essay, specifics of how it fits into the overall curriculum)',
    type: 'textArea',
  },
  {
    id: 'question',
    label: 'Essay Prompt',
    placeholder: 'Essay prompt',
    type: 'textArea',
  },
];
