import React from 'react';

interface CardProps {
  children: React.ReactNode;
  title: string;
}

const ContentCard: React.FC<CardProps> = ({ children, title }) => {
  return (
    <div className="rounded-lg bg-white shadow border border-gray-200 mt-4">
      {!!title && (
        <div className="px-4 pt-5 sm:px-6">
          <h3 className="text-lg font-semibold leading-6 text-gray-900">
            {title}
          </h3>
        </div>
      )}
      <div className="flex justify-center px-4 py-5 sm:p-6 w-full">
        {children}
      </div>
    </div>
  );
};

export default ContentCard;
