import { useQuery } from '@tanstack/react-query';

import { Container } from '../dependencyInjection/Container';
import { AssignmentMetadataContent } from '../types';

interface OrganizationData {
  data?: AssignmentMetadataContent | null;
  isLoading: boolean;
  error: Error | null;
}

export const useMetadataContentFromPath = (
  container: Container,
  path?: string
): OrganizationData => {
  const fetchMetadataContent =
    async (): Promise<AssignmentMetadataContent | null> => {
      try {
        if (!path) return null;

        const assignmentMetadataContent =
          await container.assignmentMetadataContentProvider.getByPath(path);
        if (!assignmentMetadataContent) return null;

        return assignmentMetadataContent;
      } catch (error) {
        console.error('Error fetching metadata from path:', error);
        return null;
      }
    };

  const { data, isLoading, error } = useQuery<AssignmentMetadataContent | null>(
    {
      queryKey: ['metadataContent', path],
      queryFn: fetchMetadataContent,
    }
  );

  return { data, error, isLoading };
};
