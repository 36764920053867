import { truncateToNWords } from '../utils';
import {
  AssignmentDetail,
  AssignmentMetadataContentV1,
  Breadcrumb,
  Assignment,
} from '../types';
import { AssignmentMetadataInfo } from '../components/AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';

export const mockJobId: string = 'Zo5wEyP7SOSVfnT4q96q';
export const mockAssignmentMetadataContent: AssignmentMetadataContentV1 = {
  version: 'v1',
  department: 'English',
  teacher_type: 'teacher',
  school_type: 'high school',
  course: 'English II',
  question_background:
    'Students were to write a 5 paragraph persuasive essay that utilized the text “Long Way Down” by Jason Reynolds as evidence to support their opinion.',
  question:
    'Write an essay stating your position on whether revenge is ever justified.',
  rubric: [
    {
      id: '1',
      name: 'focus/claim',
      description:
        'Insightfully addresses all parts of the writing task, introduces precise claim(s) in a sophisticated thesis statement',
      points_possible: '5',
    },
    {
      id: '2',
      name: 'organization/structure',
      description:
        'Maintains a logical and seamless organizational structure, thoroughly develops complex claim(s) with relevant body paragraphs, provides a meaningful and reflective conclusion, creates cohesion through skillful use of transitions between ideas',
      points_possible: '5',
    },
    {
      id: '3',
      name: 'evidence/support',
      description:
        'Provies substantial and pertinent evidence to support claim(s),seamlessly and effectively integrates and cites credible sources and/or textual evidence,convincingly refutes specific counter-claims(s)',
      points_possible: '5',
    },
    {
      id: '4',
      name: 'analysis',
      description:
        'Shows insightful understanding of topic/text,uses persuasive and valid reasoning to connect evidence with claim(s)',
      points_possible: '5',
    },
    {
      id: '5',
      name: 'language',
      description:
        'Uses purposeful and varied sentence structure, contains minimal to no errors in conventions (grammer, punctuation, spelling, capitalization), strategically uses academic and domain-specific vocabulary clearly appropriate for the audience and purpose',
      points_possible: '5',
    },
  ],
};

export const mockBreadcrumbs: Breadcrumb[] = [
  { name: 'Assignments', href: '/assignments', current: false },
  {
    name: truncateToNWords(mockAssignmentMetadataContent.question, 20),
    href: '#',
    current: true,
  },
  { name: 'Lindsay Walton', href: '#', current: true },
];

export const mockStudentSubmission: string = `
MOCK STUDENT SUBMISSION
`;

export const mockGradingResult = [
  {
    output: {
      grade: '85%',
      rubric_grading: {
        'focus/claim': {
          points_earned: '4.0',
          explanation:
            "The student's essay adequately addresses the writing task and introduces a claim about whether revenge is justified. However, the thesis statement could be more sophisticated and precise.",
          highlighted_text: [
            'There is always a justification to revenge if you are doing it for a worthy cause.',
            'However, getting revenge on somebody can be dangerous but if it is for the right reasons; do what you got to do.',
          ],
        },
        'organization/structure': {
          points_earned: '4.5',
          explanation:
            'The essay maintains a logical organizational structure and develops the claim with relevant body paragraphs. The conclusion is meaningful, but there could be more skillful use of transitions between ideas.',
          highlighted_text: [
            'First, in the poem The Long Way Down he brother Shawn got shot so Wil took in consecration to get revenge to show trust to his brother.',
            'As well as, in the movie Til Death Do Us Part she killed her abusive and crazy husband.',
            'Additionally, I have a real life about revenge that happened in my family.',
            'Given these points, getting revenge always have it reasons for it and they cause, and yes revenge is good if your intentions are good and the person you getting revenge deserve it.',
            'In essence, the points I gave about revenge being justified.',
          ],
        },
        'evidence/support': {
          points_earned: '4.5',
          explanation:
            "The essay provides substantial evidence to support the claim, including examples from the text 'Long Way Down' and a personal anecdote. The integration of evidence could be more seamless and convincing refutation of counter-claims could be included.",
          highlighted_text: [
            'In the story he went on a mission to get revenge because crying is not accepted in his world of living because it shows weakness.',
            'For example, when he is going on the mission to get revenge, he sees ghosts of dead loved ones trying to push him to not do but he does not listen he took matters in his own hands.',
            'Which shows revenge is justified based on his brother\u2019s death.',
            'The justification for her revenge is because he was controlling, he would beat on her in the dark so nobody would know and think they relationships is prefect.',
            'He was also crazy enough to stalker her when she left and to kidnap her best friend to show her that he was not playing.',
            'Which that pushed her to the limit to kill so she can have a better life.',
            'My momma used to be with a crazy man while she was in college that made her drop out.',
            'He used to try to take control like she was his dog.',
            'Now let us get to the revenge part one day they were at home with my family, and he was there him and my momma got into a fight and a weapon got involved so my aunt jumped in.',
            'He got stab and the called the police.',
            'My mom went to jail but got out because she had witness of what happened she got out for self-defense.',
            'So therefore, revenge cane be justified if it\u2019s for a good reason or cause.',
          ],
        },
        analysis: {
          points_earned: '4.0',
          explanation:
            'The essay shows some understanding of the topic and uses reasoning to connect evidence with the claim. However, the analysis could be more insightful and persuasive.',
          highlighted_text: [
            'Therefore, revenge can be justified in many ways.',
          ],
        },
        language: {
          points_earned: '4.5',
          explanation:
            'The essay uses purposeful and varied sentence structure and contains minimal errors in conventions. The vocabulary is appropriate for the audience and purpose.',
          highlighted_text: [
            'Revenge is an act of passion, vengeance of justice.',
            'Which shows revenge is justified based on his brother\u2019s death.',
            'The justification for her revenge is because he was controlling, he would beat on her in the dark so nobody would know and think they relationships is prefect.',
            'Which that pushed her to the limit to kill so she can have a better life.',
            'So therefore, revenge cane be justified if it\u2019s for a good reason or cause.',
          ],
        },
      },
      explanation:
        "The student's essay demonstrates a solid understanding of the topic and effectively supports their claim that revenge can be justified. The essay is well-organized and provides relevant evidence from the text 'Long Way Down' as well as a personal anecdote. The language used is appropriate and demonstrates a good command of sentence structure and conventions. However, there is room for improvement in developing a more sophisticated thesis statement, seamlessly integrating evidence, and providing more insightful analysis. Overall, the student has shown proficiency in most areas of the rubric.",
      suggestions: [
        'Refine the thesis statement to be more precise and sophisticated.',
        'Work on seamlessly integrating evidence from the text and personal anecdotes.',
        'Provide more insightful analysis by connecting evidence to the claim in a persuasive manner.',
      ],
      follow_up_questions: [
        'How could you further develop your thesis statement to make it more precise and sophisticated?',
        'What strategies could you use to seamlessly integrate evidence from the text and personal anecdotes?',
        'How can you strengthen your analysis by providing more persuasive reasoning and connections between evidence and claim?',
      ],
      grammar_mistakes: [
        {
          mistake: 'consecration',
          explanation:
            "The word 'consecration' is not used correctly in this context. It seems to be a mistake or a misunderstanding of the intended word.",
          correction: 'consideration',
        },
        {
          mistake: 'relationships is prefect',
          explanation:
            "The subject-verb agreement is incorrect. 'Relationships' should be plural to match the verb 'are'.",
          correction: 'relationships are perfect',
        },
        {
          mistake: 'revenge cane be justified',
          explanation:
            "The word 'cane' is a spelling mistake. The correct word should be 'can'.",
          correction: 'revenge can be justified',
        },
      ],
    },
    model_version: 'gpt-3.5-turbo-16k',
    model_temperature: 0.0,
    student_id: 'dummy_id_sample.docx',
    input_file_path:
      'users/YuKoPREVPYYTrRr2ZzHMNo4wFf92/uploads/bypass_form/plaintext/sample.docx',
    prompt_args_path:
      'users/YuKoPREVPYYTrRr2ZzHMNo4wFf92/uploads/bypass_form/plaintext/bypass_form_args.json',
    job_id: 'Zo5wEyP7SOSVfnT4q96q',
    user_id: 'YuKoPREVPYYTrRr2ZzHMNo4wFf92',
  },
];

export const mockAssignmentQuestion: string =
  "1.17 (Watch as an Object) You're probably wearing on your wrist one of the world's most common types of objects - a watch. Discuss how each of the following terms and concepts applies to the notion of a watch: object, attributes, behaviors, class, inheritance (consider, for example, an alarm clock), abstraction, modeling, messages, encapsulation, interface and information hiding.";

export const mockAssignmentDetails: AssignmentDetail[] = [
  {
    name: truncateToNWords(mockAssignmentQuestion, 20),
    students: [
      {
        name: 'Lindsay Walton',
        score: '24 / 25 points',
        email: 'lindsay.walton@example.com',
        date: '2d ago',
      },
      {
        name: 'Courtney Henry',
        score: '10 / 25 points',
        email: 'courtney.henry@example.com',
        date: '1d ago',
      },
    ],
  },
  // More students...
];

export const mockAssignmentList: Assignment[] = [
  {
    assignment_id: '1',
    href: '/assignments/1',
    assignment_name: 'mock_assignment_1',
    created_at: new Date('March 16, 2023'),
    metadata_content: mockAssignmentMetadataContent,
    grading_job_id: 'mock_job_1',
    conversion_job_id: 'mock_job_2',
    assignment_metadata_info: {
      assignment_metadata_id: 'mock_id_1',
      assignment_metadata_path: 'mock_metadata_path',
    } as AssignmentMetadataInfo,
  },
  {
    assignment_id: '2',
    href: '/assignments/2',
    assignment_name: 'mock_assignment_2',
    created_at: new Date('February 16, 2023'),
    metadata_content: mockAssignmentMetadataContent,
    grading_job_id: 'mock_job_3',
    conversion_job_id: 'mock_job_4',
    assignment_metadata_info: {
      assignment_metadata_id: 'mock_id_2',
      assignment_metadata_path: 'mock_metadata_path',
    } as AssignmentMetadataInfo,
  },
  {
    assignment_id: '3',
    href: '/assignments/3',
    assignment_name: 'mock_assignment_3',
    created_at: new Date('May 25, 2023'),
    metadata_content: mockAssignmentMetadataContent,
    grading_job_id: 'mock_job_5',
    conversion_job_id: 'mock_job_6',
    assignment_metadata_info: {
      assignment_metadata_id: 'mock_id_3',
      assignment_metadata_path: 'mock_metadata_path',
    } as AssignmentMetadataInfo,
  },
  {
    assignment_id: '4',
    href: '/assignments/4',
    assignment_name: 'mock_assignment_4',
    created_at: new Date('June 7, 2023'),
    metadata_content: mockAssignmentMetadataContent,
    grading_job_id: 'mock_job_7',
    conversion_job_id: 'mock_job_8',
    assignment_metadata_info: {
      assignment_metadata_id: 'mock_id_4',
      assignment_metadata_path: 'mock_metadata_path',
    } as AssignmentMetadataInfo,
  },
  {
    assignment_id: '5',
    href: '/assignments/5',
    assignment_name: 'mock_assignment_5',
    created_at: new Date('June 7, 2023'),
    metadata_content: mockAssignmentMetadataContent,
    grading_job_id: 'mock_job_9',
    conversion_job_id: 'mock_job_10',
    assignment_metadata_info: {
      assignment_metadata_id: 'mock_id_5',
      assignment_metadata_path: 'mock_metadata_path',
    } as AssignmentMetadataInfo,
  },
];
