import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';
import { EditNote as EditNotIcon } from '@mui/icons-material';

const EditIconButton: React.FC = () => {
  return (
    <Link to="/settings">
      <Tooltip title="Edit">
        <IconButton color="primary">
          <EditNotIcon />
        </IconButton>
      </Tooltip>
    </Link>
  );
};

export default EditIconButton;
